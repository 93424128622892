// import {Device} from "../../configuration";

//const replaceSlideIn = (Device.isIOS || Device.isSafari)
//const replaceSlideIn = true

export const transitionRightLeft = {
	visible: {
		x:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		x:"50%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionRightLeftSpring = {
	visible: {
		x:0,
		opacity: 1,
		transition:{
			type: "spring",
			stiffness: 500,
			damping: 20,

		},
	},
	hidden: {
		x:"50%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionRightLeftBig = {
	visible: {
		x:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		x:"200%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionRightLeftSmall = {
	visible: {
		x:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		x:"200px",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionLeftRight = {
	visible: {
		x:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		x:"-500%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionAmplify = {
	visible: {
		scale:1,
		opacity: 1,
		transition:{ ease: [.2,.5,.5,1], duration: 1 }
	},
	hidden: {
		scale:0,
		opacity: 0,
		transition:{ ease: [.2,.5,.5,1], duration: .5 }
	}
}

export const transitionBottomTop = {
	visible: {
		y:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		y:"100%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionTopBottom = {
	visible: {
		y:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		y:"-100%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionPolicy = {
	visible: {
		y:0,
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 2, delay:3 },

	},
	hidden: {
		y:"100%",
		opacity: 0,
		// transition:{ duration: 0 }
	}
}


export const transitionOpacity = {
	visible: {
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 1 }
	},
	hidden: {
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionOpacitySlow = {
	visible: {
		opacity: 1,
		transition:{ ease: [.5,0,0,1], duration: 5 }
	},
	hidden: {
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionOpacitySlowStartFast = {
	visible: {
		opacity: 1,
		transition:{ ease: [1,1,0,0.75], duration: 2 }
	},
	hidden: {
		opacity: 0,
		// transition:{ duration: 0 }
	}
}

export const transitionAnimatedTextDefault = {
	hidden: {
		y: "200%",
		transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
	},
	visible: {
		y: 0,
		transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
	}
}

export const transitionInstant = {
	hidden: {
		opacity:0,
	},
	visible: {
		opacity:1,
	}
}

// export const animationSlideDownDelayed = {
// 	...animationSlideDown,
// 	animate:{
// 		...animationSlideDown.animate,
// 		transition:{
// 			...animationSlideDown.animate.transition,
// 			delay:.5,
// 		}
// 	}
// }
