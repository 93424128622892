import React, { useCallback, useEffect, useMemo, useState } from "react"

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";

import Slider from 'react-rangeslider'
import { MoneyFormat } from "../../../helpers/utils";
import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import {
	transitionAmplify,
	transitionBottomTop,
	transitionOpacitySlowStartFast,
	transitionRightLeft, transitionRightLeftBig, transitionRightLeftSpring
} from "../../../components/transitions";
import AnimatedContent from "../../components/AnimatedContent";
import AnimatedDiv from "../../components/AnimatedDiv";
import SectionFooterText from "pages/components/SectionFooterText";

const RangeValueInput = (
	{
		title,
		value = 0,
		formatter = value => value,
		onChange,
		minValue = 0,
		maxValue = 100,
		stepValue = 1,
	}
) => {

	return <>
		<AnimatedContent variants={transitionRightLeftSpring} className="debug5 range-item">
			<div className="d-flex flex-column font-size-pt1">
				<div className=" ">{title}</div>
				<div className=" font-weight-600 font-size-pt14 text-uppercase">{formatter(value)}</div>
				<div className="pt-4 pb-1">
					<Slider
						min={minValue}
						max={maxValue}
						step={stepValue}
						value={value}
						orientation={'horizontal'}
						reverse={false}
						tooltip={false}
						// labels={}
						// handleLabel={String}
						format={formatter}
						// onChangeStart={() => {}}
						onChange={onChange}
					// onChangeComplete={onChange}
					/>
				</div>
				<div className="d-flex flex-row debug4">
					<div className="flex-fill">
						{formatter(minValue)}
					</div>
					<div className="flex-fill debug text-align-right">
						{formatter(maxValue)}
					</div>
				</div>
			</div>
		</AnimatedContent>
	</>
}


const friendlyNumber = (value) => {
	if (value > 1000)
		return {
			value: value / 1000,
			unity: "mil"
		}

	return {
		value: Math.floor(value),
		unity: ""
	}
}

const SimulationSection = ({ history, props }) => {

	const [initialResources, setInitialResources] = useState(10000000)
	const [clientPerYear, setClientPerYear] = useState(20)
	const [averageApplication, setAverageApplication] = useState(500000)

	const result = useMemo(() => {
		const aporte = initialResources
		const qtdNovosClientes = clientPerYear
		const patrimonioMedio = averageApplication


		const referenciaBonus = 50000
		const valorBonus = 200
		const recorrenciaSquadra = 0.0362210547432129 / 100
		const recorrenciaParceria = 0.0217499428268431 / 100
		//        const recorrenciaSquadraBruta  = 0.0415714844729020 / 100
		//        const recorrenciaParceriaBruta = 0.0249656907416185 / 100
		const recorrenciaSquadraBruta = 0.50 / 100
		const recorrenciaParceriaBruta = 0.30 / 100


		//        const inicial =
		//			(qtdNovosClientes * patrimonioMedio / referenciaBonus * valorBonus * 12) +
		//			((aporte / referenciaBonus * valorBonus) + aporte * (Math.pow(1 + recorrenciaParceriaBruta, 12) - 1));
		const inicial = ((aporte / referenciaBonus * valorBonus) + aporte * (Math.pow(1 + recorrenciaParceriaBruta, 1) - 1)) +
			(((patrimonioMedio * qtdNovosClientes) / referenciaBonus * valorBonus) + (patrimonioMedio * qtdNovosClientes) * (Math.pow(1 + recorrenciaParceriaBruta, 1) - 1));

		const final = ((aporte / referenciaBonus * valorBonus) + aporte * (Math.pow(1 + recorrenciaSquadraBruta, 1) - 1)) +
			(((patrimonioMedio * qtdNovosClientes) / referenciaBonus * valorBonus) + (patrimonioMedio * qtdNovosClientes) * (Math.pow(1 + recorrenciaSquadraBruta, 1) - 1));


		//        const final = (patrimonioMedio * qtdNovosClientes) * recorrenciaSquadraBruta * 78 +
		//			(qtdNovosClientes * patrimonioMedio / referenciaBonus * valorBonus * 12) +
		//			((aporte / referenciaBonus * valorBonus) + aporte * (Math.pow(1 + recorrenciaSquadraBruta, 12) - 1));

		return {
			initial: friendlyNumber(inicial),
			final: friendlyNumber(final)
		}
	}, [initialResources, clientPerYear, averageApplication])

	return <>
		<Section className="section-simulation" id="simular-seus-ganhos">

			{/* <div className="content-left d-flex justify-content-end align-items-end info-text"> */}
			<div className="content-left d-flex flex-column">
				<div className="debug2 flex-grow-1 flex-fill d-flex flex-column justify-content-center text-align-right content-left-text">
					<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 ">
						<AnimatedContent
							variants={transitionOpacitySlowStartFast}
							className="debug5 font-family-title "
						>
							Os <span className="font-weight-800 font-family-title">Planejadores Fiduc</span>
							<br />recebem de forma recorrente
							<br />um percentual fixo sobre
							<br />a carteira de investimentos
							<br />dos seus clientes, além de
							<br />bônus adicionais.
						</AnimatedContent>
					</AnimatedDivChilds>
				</div>
				<div className="debug2 flex-grow-0">
					<div className="fiduc-slogan-bottom ">
						<SectionFooterText />
					</div>
				</div>

			</div>
			<div className="content-right pt-5 d-flex flex-grow-1 flex-fill d-flex flex-column  ">
				<div className="debug3 ">
					<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 text-align-left text-info-container">
						<AnimatedCharacters
							text={'Simular seus ganhos'}
							splitChars={"none"}
							fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase"
							variants={transitionBottomTop}
						/>
						{/*<AnimatedCharacters*/}
						{/*	text={"sua receita"}*/}
						{/*	splitChars='none'*/}
						{/*	fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase"*/}
						{/*	variants={transitionBottomTop}*/}
						{/*/>*/}
						{/*<AnimatedCharacters*/}
						{/*	text={"Na Fiduc você é o responsável pela sua remuneração."}*/}
						{/*	splitChars='none'*/}
						{/*	fontClassName="font-family-title font-weight-500 font-size-pt0 text-uppercase"*/}
						{/*	variants={transitionBottomTop}*/}
						{/*/>*/}
					</AnimatedDivChilds>
				</div>
				<AnimatedDivChilds staggerChildren={.15} className="debug3 d-flex flex-wrap no-select pt-5 overflow-hidden">
					<RangeValueInput
						title="Aporte de recursos inicial (R$)"
						minValue={0}
						maxValue={50000000}
						stepValue={5000000}
						value={initialResources}
						formatter={value => MoneyFormat(value, '', 0)}
						onChange={value => setInitialResources(value)}
					/>
					<RangeValueInput
						title="Novos clientes por ano"
						minValue={0}
						maxValue={100}
						stepValue={10}
						value={clientPerYear}
						formatter={value => value}
						onChange={value => setClientPerYear(value)}
					/>
					<RangeValueInput
						title="Aplicação média do Cliente (R$)"
						minValue={0}
						maxValue={5000000}
						stepValue={100000}
						value={averageApplication}
						formatter={value => MoneyFormat(value, '', 0)}
						onChange={value => setAverageApplication(value)}
					/>
				</AnimatedDivChilds>
				<AnimatedDiv variants={transitionBottomTop} threshold={.1} className="debug3 pt-4 pb-4 d-flex flex-column">
					<div className="text-align-center font-size-pt1">Seu ganho bruto no primeiro ano será (R$)</div>
					<div className="py-2 text-align-center font-weight-500 font-size-pt14 ">
						<span className="text-nowrap">
							<span>{MoneyFormat(result?.initial?.value, '', 0)}</span>
							<span className="font-size-pt12 ps-2">{result?.initial?.unity}</span>
						</span>
						{" "}/{" "}
						<span className="text-nowrap">
							<span>{MoneyFormat(result?.final?.value, '', 0)}</span>
							<span className="font-size-pt12 ps-2">{result?.final?.unity}</span>
						</span>
					</div>
					<div className="font-size-pt1 text-align-center">Para Renda Fixa seu ganho é aproximadamente 75% dos valores acima</div>
				</AnimatedDiv>
			</div>
		</Section>
	</>

}

export default SimulationSection

