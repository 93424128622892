import {loadAllRemoteLists} from "../../helpers/constants/remoteLists";
import {logoutResetStates} from "./state";
import lStorage from "../../helpers/gestora/lStorage";
import {setFocusOnSelector} from "../../components/Common/CustomForm/Utils/DomProp";
const _ = require('lodash')

export const moveToPage = ( {state}, pageId ) => {
	setFocusOnSelector(`page-id-${pageId}`, null, '', -64)
}

export const setIntroRegisterButtonVisible = ( {state}, visible ) => {
	state.app.introRegisterButtonVisible = visible
}

export  const resetOvermind = ( {state}, states=logoutResetStates ) => {

	// console.log('states', states)

	for (const stateName in states) {
		const defaultState = states[stateName]
		for (const varName in defaultState) {

			const defaultValue = _.cloneDeep(defaultState[varName])
			// console.log(`state.${stateName}.${varName}`, defaultValue)

			if (typeof defaultValue == 'object') {
				state[stateName][varName] = {...defaultValue}
			} else if (typeof defaultValue == 'array') {
				state[stateName][varName] = [...defaultValue]
			} else {
				state[stateName][varName] = defaultValue
			}
		}
	}
	state.forms.LoginForm.model.document = lStorage.get('last-login-document')
}

export  const loadRemoteLists = ( { state } ) => {
	if (AuthInfo() && !state.remoteListsLoaded && !state.remoteListsLoading) {
		state.remoteListsLoading = true
		return loadAllRemoteLists()
			.then(() => {
				state.remoteListsLoaded = true
			})
	} else {
		return Promise.resolve()
	}

}


