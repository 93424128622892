import React, { useEffect, useState } from "react"

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";

import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import {
	transitionAmplify,
	transitionBottomTop, transitionLeftRight, transitionOpacity,
	transitionOpacitySlowStartFast,
	transitionRightLeft, transitionRightLeftSmall
} from "../../../components/transitions";
import AnimatedContent from "../../components/AnimatedContent";

import BradescoImage from "../../../assets/images/partners/bradesco.png"
import RioBravoImage from "../../../assets/images/partners/riobravo.png"
import IcatuImage from "../../../assets/images/partners/icatu.png"
import PWCImage from "../../../assets/images/partners/pwc.png"
import AnimatedDiv from "../../components/AnimatedDiv";
import SectionFooterText from "pages/components/SectionFooterText";

const EntreAspas = ({ children }) => (
	<>
		&#10077;{children}&#10078;
	</>
)

const TestimonialBlock = (
	{
		title,
		link,
		imageClass,
		description,
	}
) => {

	return <>
		<AnimatedContent threshold={.5} variants={transitionAmplify} className="debug5 image-item ">
			<div className="image-block d-flex font-size-pt1 align-items-center flex-column ">
				<div className={`image ${imageClass} flex-grow-0`}>

				</div>
				<div className="text d-flex flex-column flex-grow-1 flex-fill">
					<div className="font-weight-600 pb-3">
						<a href={link} target="_blank" rel="noopener noreferrer">
							{title}&nbsp;
							<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
								<path fill="#056571" d="M6 1h5v5L8.86 3.85 4.7 8 4 7.3l4.15-4.16L6 1ZM2 3h2v1H2v6h6V8h1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z" />
							</svg>
						</a>
					</div>
					<div className="font-size-pt0">{description}</div>
				</div>
			</div>
		</AnimatedContent>
	</>
}

const TestimonialsSection = ({ history, props }) => {

	return <>
		<Section className="section-testimonials" id="depoimentos">

			<div className="content-left d-flex flex-column">
				<div className="debug2 flex-grow-1 flex-fill d-flex flex-column justify-content-center text-align-right content-left-text">
					<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 ">
						<AnimatedContent
							variants={transitionOpacitySlowStartFast}
							className="debug5 font-family-title"
						>
							<br />Traga você também a sua
							<br />experiência para a <span className="font-weight-800 font-family-title">Fiduc</span>.
						</AnimatedContent>
					</AnimatedDivChilds>
				</div>

				<div className="debug2 flex-grow-0">
					<div className="fiduc-slogan-bottom ">
						<SectionFooterText />
					</div>
				</div>
			</div>
			<div className="content-right d-flex flex-grow-1 flex-fill d-flex flex-column" style={{ paddingTop: 65 }}>
				<div className="debug3 ">
					<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 text-align-left text-info-container">
						<AnimatedCharacters
							text={'Depoimentos'}
							splitChars={'none'}
							fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase"
							variants={transitionRightLeft}
						/>
					</AnimatedDivChilds>
				</div>
				<AnimatedDivChilds staggerChildren={.15} className="content debug3 no-select pt-5">
					<TestimonialBlock
						title={"Rodrigo Ferro"}
						link="https://www.linkedin.com/in/rodrigoportoferro/"
						description={<EntreAspas>
							Sempre tive dúvida se o que estavam me
							oferecendo como opção de investimentos
							era o melhor. Hoje tenho certeza que
							a <span className="font-weight-800 font-family-title">Fiduc</span> é a melhor opção por ter total
							alinhamento de interesses. Se é bom para
							meus investimentos também é bom para
							meus clientes.
						</EntreAspas>}
						imageClass={"image-rodrigo-ferro-1"}
					/>
					<TestimonialBlock
						title={"Fernanda Prado"}
						link="https://www.linkedin.com/in/pradofernanda/"
						description={<EntreAspas>
							Ser <span className="font-weight-800 font-family-title">Planejadora Fiduciária</span> é oportunidade
							para entregar uma solução que ajuda os
							clientes a atingirem seus objetivos
							e, ao mesmo tempo, potencializar o
							crescimento dos nossos negócios.
						</EntreAspas>}
						imageClass={"image-fernanda-prado"}
					/>
					<TestimonialBlock
						title={"Rogério Saltes, CFP®"}
						link="https://www.linkedin.com/in/rogeriosaltes/"
						description={<EntreAspas>
							<span className="font-weight-800 font-family-title">Fiduc</span> é uma solução fiduciária
							democratizada na gestão de reservas,
							com ferramentas muito úteis de uso
							prático no dia-a-dia da elaboração
							de planejamento financeiro, o que
							proporciona enorme valor agregado
							na jornada junto a meus clientes.
						</EntreAspas>}
						imageClass={"image-rogerio-saltes"}
					/>
					{/* <TestimonialBlock
						title={"Carlos Caporal"}
						description={<>
							” Em menos de dois anos, ser 
							<span className="font-weight-800 font-family-title"> Planejador fiduciário</span> já equivale
							a <span className="font-weight-800 font-family-title">45%</span> da minha receita mensal,
							com real crescimento. ”

						</>}
						imageClass={"image-caporal"}
					/>
					<TestimonialBlock
						title={"Marcelo Cantiere"}
						description={<>
							” A <span className="font-weight-800 font-family-title">Fiduc</span> tem um modelo de negócio
							inovador. perfeito para quem deseja total
							apoio de uma empresa com uma visão
							de futuro. Junte-se à nós. ”
						</>}
						imageClass={"image-cantiere"}
					/> */}
				</AnimatedDivChilds>
			</div>
		</Section>
	</>

}

export default TestimonialsSection

