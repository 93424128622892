import React, { useEffect, useMemo, useState } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";

import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import {
	transitionBottomTop,
	transitionOpacitySlowStartFast,
	transitionRightLeft
} from "../../../components/transitions";
import AnimatedContent from "../../components/AnimatedContent";
import CustomForm from "../../../components/Common/CustomForm/Form";
import FormWizard from "../../../components/Common/CustomForm/FormWizard";
import { useOvermind, useOvermindForm } from "../../../overmind";
import {
	certificationOptions,
	occupationAreaOptions,
	schoolingOptions,
	statesBrOptions, yesNoOptions
} from "../../../helpers/constants/formLists";
import ButtonSecondary from "../../../components/Common/Button/ButtonSecondary";
import { dateBrToISO, onlyNumbers } from "../../../helpers/utils";

const axios = require('axios')
const painelUrl = process?.env?.REACT_APP_PAINEL_URL

const initialState = { emManutencao: window.emManutencao || false }
import lStorage from "../../../helpers/gestora/lStorage";
import SectionFooterText from "pages/components/SectionFooterText";
import styled from "styled-components";

const Link = styled.a`
	text-decoration: underline !important;
`

const FormPlannerSection = ({ history, props }) => {

	const formId = 'FormPlanner'
	const form = useOvermindForm(formId)
	const [submitting, setSubmitting] = useState(false)
	// const [loading, setLoading] = useState(false)
	const app = useOvermind('app')
	const cookies = useOvermind('cookies')
	const [done, setDone] = useState(false)
	const [mensagem, setMensagem] = useState(null)

	const [dadosLink, setDadosLink] = useState(initialState);
	const [dadosExtras, setDadosExtras] = useState(initialState);
	useEffect(() => {
		if (dadosLink.emManutencao) return
		let partes = window.location.pathname.split('/')
		let [_, tipo, encurtador] = partes;

		if (tipo !== 'i')
			tipo = ''


		//Seta/Recupera do localStorage em caso de refresh em outra página
		if (partes?.length === 3) {
			lStorage.set('linkIndicacao', partes)
		} else if (partes?.length < 3) {
			//url do site
			partes = lStorage.get('linkIndicacao')
			if (partes && partes?.length === 3) {
				[_, tipo, encurtador] = partes
			}
		}

		if (tipo == 'i') {
			const url = `${painelUrl}candidato/recrutamento/${encurtador}`;
			axios
				.get(url)
				.then(({ data }) => {
					if (data.success) {
						const dadosLink = data.data
						setDadosLink(dadosLink)

						let idParceiro = null
						let idMentor = null
						if (dadosLink.tipo === 'mentor') {
							if (dadosLink.parceiros?.length == 1) {
								idParceiro = dadosLink.parceiros[0].id
							}
						} else if (dadosLink.tipo == 'parceiro') {
							if (dadosLink.mentores?.length == 1) {
								idMentor = dadosLink.mentores[0].id
							}
						}
						form.actions.setModelValueByPath({ formId, path: 'encurtador', value: encurtador })
						form.actions.setModelValueByPath({ formId, path: 'idParceiro', value: idParceiro })
						form.actions.setModelValueByPath({ formId, path: 'idMentor', value: idMentor })
						setDadosExtras({ encurtador, idMentor, idParceiro })
					} else {
						if (window.location.pathname.split('/')[1] === 'i') {
							setDadosLink({ invalido: true })
						} else {
							// se a página atual foi acessada sem o link na URL então o link que foi 
							// usado na consulta estava salvo no localStorage e está inválido
							lStorage.set('linkIndicacao', null)
							setDadosLink({ tipo: 'organico' })
						}
					}
				})
				.catch(err => {
					console.log(err)
				})
		} else if (partes?.length === 2) {
			//Url do site
			setDadosLink({ tipo: 'site_url' })
		} else if (tipo !== '') {
			setDadosLink({ invalido: true })
		} else {
			setDadosLink({ tipo: 'organico' })
		}
	}, []);



	const screens = useMemo(() => {

		let mentorFields = [{
			id: 'idParceiro',
			type: 'hidden',
		}]
		if (dadosLink.tipo === 'mentor' && dadosLink.parceiros.length > 1) {
			mentorFields = [
				{
					id: 'idParceiro',
					caption: "Parceiros",
					type: 'select2',
					noEmptyOption: true,
					options: dadosLink.parceiros.map(el => {
						return { id: el.id.toString(), caption: el.nome.toUpperCase() }
					}),
					required: true,
					col: 6,
				},
			]
		}

		let parceiroFields = [{
			id: 'idMentor',
			type: 'hidden',
		}]
		if (dadosLink.tipo === 'parceiro' && dadosLink.mentores.length > 1) {
			parceiroFields = [
				{
					id: 'idMentor',
					caption: "Mentores",
					type: 'select2',
					noEmptyOption: true,
					options: dadosLink.mentores.map(el => {
						return { id: el.id.toString(), caption: el.nome.toUpperCase() }
					}),
					required: true,
					col: 6,
				},
			]
		}

		return [
			{
				id: formId,
				fields: [
					...mentorFields,
					...parceiroFields,
					{
						id: 'encurtador',
						type: 'hidden',
					},
					{
						id: 'nome',
						caption: "Nome completo",
						type: 'text',
						required: true,
						row: true,
						col: 6,
					},
					{
						id: 'cpf',
						caption: "CPF",
						type: 'cpf',
						required: true,
						col: 6,
					},
					{
						id: 'telefone',
						caption: "Telefone",
						type: 'phone',
						required: true,
						col: 6,
						row: true,
					},
					{
						id: 'email',
						caption: "E-mail",
						type: 'email',
						required: true,
						col: 6,
					},
					{
						id: 'dataNascimento',
						caption: "Data de nascimento",
						type: 'date',
						required: true,
						col: 6,
					},
					{
						id: 'linkedin',
						caption: "Informe seu Linkedin",
						// info:<div className="opacity-75" style={{paddingLeft:'.75rem'}}>(ex: https://linkedin/in/nome)</div>,
						placeHolder: '(ex: https://linkedin/in/nome)',
						icon: 'fab fa-linkedin-in',
						type: 'text',
						required: false,
						col: 6,
					},

					// {
					// 	id:'cidade',
					// 	caption:"Cidade onde reside",
					// 	type:'text',
					// 	required:true,
					// 	col:6,
					// },
					// {
					// 	id:'uf',
					// 	caption:"Estado",
					// 	type:'select2',
					// 	noEmptyOption:true,
					// 	options:statesBrOptions,
					// 	required:true,
					// 	col:6,
					// },
					// {
					// 	id:'exerceAtividadeComercial',
					// 	caption:"Você já exerceu atividade comercial ?",
					// 	type:'select2',
					// 	noEmptyOption:true,
					// 	options:yesNoOptions,
					// 	// optionsCol:1,
					// 	// center:true,
					// 	defaultValue:'0',
					// 	inline:true,
					// 	reverse:true,
					// 	required:true,
					// 	col:6,
					// },
					// {
					// 	id:'genero',
					// 	caption:"Gênero",
					// 	type:'select2',
					// 	noEmptyOption:true,
					// 	options:genderRecrutOptions,
					// 	required:true,
					// 	col:6,
					// },
					// {
					// 	id:'idEscolaridade',
					// 	caption:"Escolaridade",
					// 	type:'select2',
					// 	noEmptyOption:true,
					// 	options:schoolingOptions,
					// 	required:true,
					// 	col:6,
					// },
					// {
					// 	id:'idAreaAtuacaoLst',
					// 	caption:"Áreas que já atuou ou atua",
					// 	type:'select2',
					// 	noEmptyOption:true,
					// 	options:occupationAreaOptions,
					// 	required:true,
					// 	col:6,
					// },
					// {
					// 	id:'idCertificacaoLst',
					// 	caption:"Certificações",
					// 	type:'select2',
					// 	noEmptyOption:true,
					// 	options:certificationOptions,
					// 	required:true,
					// 	col:6,
					// 	onChange : (values, setFieldState) => {
					// 		const value = values?.idCertificacaoLst
					// 		setFieldState('outraCertificacao', 'visible', value=='-1')
					// 	}
					// },
					// {
					// 	id:'outraCertificacao',
					// 	caption:"Outra certificação",
					// 	type:'text',
					// 	required:true,
					// 	col:6,
					// },
					{
						id: 'acceptedCookies',
						acceptCaption: <div className="pe-5">
							Concordo com o uso de cookies para garantir uma melhor experiência deste site e autorizo a coleta das minhas informações.
							<br />
							Para entender como tais dados são tratados, acesse a{" "}
							<Link
								className="font-weight-600 text-white"
								href="https://fiduc.com.br/wp-content/uploads/2022/09/fiduc-fgf-2022-08-politica-de-privacidade.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Política de Privacidade e Utilização de Dados Pessoais.
							</Link>
						</div>,
						type: 'checkaccept',
						required: true,
						customError: "É necessário aceitar a coleta de informações para continuar.",
						col: 12,
						row: true,
					}
				],
			},
		]
	}, [dadosExtras]);

	const handleSubmit = (values) => {

		// console.log({values})
		// return
		const cpf = onlyNumbers(values.cpf)

		const painelValues = {
			...values,
			cpf,
			idParceiro: values.idParceiro ? parseInt(values.idParceiro) : null,
			idMentor: values.idMentor ? parseInt(values.idMentor) : null,
			temOutraCertificacao: false,
			idCertificacaoLst: [],
			exerceAtividadeComercial: values.exerceAtividadeComercial === '1',
			dataNascimento: dateBrToISO(values.dataNascimento),
			cidade: ''
		}
		// console.log('values', values)
		// console.log('painelValues', painelValues)
		// return

		setSubmitting(true)
		axios
			.post(`${painelUrl}candidato/recrutamento`, painelValues)
			.then(async res => {
				const data = res.data
				if (data.success) {
					if (!cookies.state.consent?.status) {
						await cookies.actions.setConsentStatus('accepted')
					}
					await cookies.actions.updateConsentDocumentPublic(cpf)
					form.actions.resetForm({ formId, newModel: {} })
					app.actions.moveToPage('seja-um-planejador')
					setDone(true)
				} else {
					if (data.message) {
						setMensagem(data.message)
					} else {
						setMensagem(`Houve algum erro ao lançar os dados. Por favor tente novamente em alguns minutos ou entre em contato por email ou telefone.`)
					}
				}
			})
			.catch(e => {
				setMensagem(`Houve algum erro ao lançar os dados. Por favor tente novamente em alguns minutos ou entre em contato por email ou telefone.`)
			})
			.finally(() => setSubmitting(false))

	}


	return <>
		{mensagem && (
			<SweetAlert onConfirm={() => setMensagem(null)}>
				{mensagem}
			</SweetAlert>
		)}
		<Section className="form-recrut section-form-planner text-white" id="seja-um-planejador">

			<div className="content-left d-flex flex-column">
				<div className="debug2 flex-grow-1 flex-fill d-flex align-items-end justify-content-end text-align-right">

				</div>
				<div className="debug2 flex-grow-0">
					<div className="fiduc-slogan-bottom ">
						<SectionFooterText />
					</div>
				</div>
			</div>

			<div className="content-right pt-5 d-flex flex-grow-1 flex-fill d-flex flex-column  ">
				<div className="debug3 ">
					<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 text-align-left text-info-container">
						<AnimatedCharacters
							text={'SEJA FIDUC'}
							splitChars={'none'}
							fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase"
							variants={transitionRightLeft}
						/>
					</AnimatedDivChilds>
				</div>
				{
					dadosLink.invalido
						? <>
							<AnimatedDivChilds
								staggerChildren={.15}
							>
								<AnimatedCharacters
									variants={transitionBottomTop}
									className="pt-5 font-size-pt3 font-weight-600 py-2"
									text="Atenção!!"
									splitChars="none"
								>

								</AnimatedCharacters>
								<AnimatedCharacters
									variants={transitionBottomTop}
									className="font-size-pt0"
									text="Este link é inválido."
									splitChars="none"
								/>
								<AnimatedCharacters
									variants={transitionBottomTop}
									className="font-size-pt0"
									text="Verifique com o mentor/planejador o link correto."
									splitChars="none"
								/>
							</AnimatedDivChilds>
						</>
						: dadosLink.emManutencao
							? <>
								<AnimatedDivChilds
									staggerChildren={.15}
								>
									<AnimatedCharacters
										variants={transitionBottomTop}
										className="pt-5 font-size-pt3 font-weight-600 py-2"
										text="Atenção!!"
										splitChars="none"
									>

									</AnimatedCharacters>
									<AnimatedCharacters
										variants={transitionBottomTop}
										className="font-size-pt0"
										text="Estamos em manutenção."
										splitChars="none"
									/>
									<AnimatedCharacters
										variants={transitionBottomTop}
										className="font-size-pt0"
										text="Por favor acesse novamente em 1 hora."
										splitChars="none"
									/>
								</AnimatedDivChilds>
							</>
							: done
								? <AnimatedDivChilds
									staggerChildren={.15}
								>
									<AnimatedCharacters
										variants={transitionBottomTop}
										className="pt-5 font-size-pt3 font-weight-600 py-2"
										text="Inscrição realizada com sucesso!"
										splitChars="none"
									>

									</AnimatedCharacters>
									<AnimatedCharacters
										variants={transitionBottomTop}
										className="font-size-pt0"
										text="Você receberá um e-mail para falarmos dos próximos passos."
										splitChars="none"
									>

									</AnimatedCharacters>
								</AnimatedDivChilds>
								: <>

									<div>
										A Fiduc acredita no poder do impacto. Nessa linha, pedimos a todo novo Planejador que
										contribua com o plantio de uma árvore (R$39 em dezembro de 2023) por meio de parceria
										que a Fiduc firmou com a&nbsp;
										<Link
											className="font-weight-600 text-white"
											href="https://www.black-jaguar.org/pt-br/"
											target="_blank"
											rel="noreferrer"
										>
											Black Jaguar Foundation
										</Link>, ONG que tem como objetivo apoiar a  criação do Corredor de Biodiversidade do Araguaia.
									</div>


									<CustomForm
										className="needs-validation "
										formId={formId}
										screens={screens}
										model={form.state.model}
										validateOnLoad={false}
										onSubmit={handleSubmit}
									// autoFocusField={'nome'}
									>
										<div className="debug3 d-flex flex-wrap no-select pt-2 justify-content-start align-items-start">
											<FormWizard
												screenId={formId}
												screens={screens}
												formId={formId}
												validationInRealTime={true}
												// OnInputValueChange={handleInputValueChange}
												tabId={formId}
											/>
										</div>
										<AnimatedDivChilds className="flex-grow-0 pt-4 w-sm-100" >
											<AnimatedContent
												splitChars="none"
												className="debug4  "
												variants={transitionBottomTop}
											>
												<ButtonSecondary
													loading={submitting}
													caption="Quero ser Fiduc"
													fillWidth={false}
													className="w-button-medium w-sm-100"
													type={"submit"}
												/>
											</AnimatedContent>
										</AnimatedDivChilds>
									</CustomForm>
								</>
				}
			</div>
		</Section>
	</>

}

export default FormPlannerSection

