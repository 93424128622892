
//import {environment} from './configuration'
const appId = 'recrutamento-v2'

class lStorage {

	get(key) {
		try {
			const content = localStorage.getItem(appId + '-' + key)
			return !!content ? JSON.parse(content) : null
		} catch (e) {
			console.log('STORAGE GET ERROR: ', e);
			debugger
			return null
		}
	}

	set(key, value) {
		// console.log('setStorage', {key, value})
		try {
			localStorage.setItem(appId + '-' + key, value ? JSON.stringify(value) : '');
		} catch (e) {
			debugger
			console.log('STORAGE SET ERROR: ', e);
		}
	}

}



export default new lStorage();
