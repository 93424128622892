import React, {useEffect, useState} from "react"

//import LogoDucDark from "../../assets/images/logos/logo-duc-dark.svg"
import { useInView } from "react-intersection-observer";

const AnimatedClassName = (
	{
		Tag='div',
		children,
		className,
		threshold=1,
		animatedClass='appear',
		...props
	}
) => {
	const [animatedClassName, setAnimatedClassName] = useState('')

	const [ref, inView] = useInView({
		threshold
	})

	useEffect(() => {
		// console.log(inView ? "1" : "0")
		if (inView) {
			setAnimatedClassName(animatedClass)
		} else {
			setAnimatedClassName('')
		}
	}, [inView])

	return (
		<Tag
			ref={ref}
			className={`${animatedClassName} ${className}`}
			{...props}
		>
			{children}
		</Tag>
	)

}

export default AnimatedClassName

