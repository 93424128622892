import React, {createRef, useEffect, useState} from "react"

import { AvForm, AvField } from "availity-reactstrap-validation"

import { scroller } from "react-scroll";
import { useOvermind } from '../../../overmind'

const _ = require('lodash')

import {validateFieldStates, validateFormScreens} from "../../../components/Common/CustomForm/Utils/InputTypes";
import {setFocusOnSelector, setFocusOnSelectorByField} from "./Utils/DomProp";

let formValidationTimer

function CustomForm( props) {
    let formRef

    const {
        children,
        formId,
		validateOnLoad = false,
		screens = [],
		defaultValues={},
        onPreviousLast = () => {},
		targetScrollContainer,
        onNextLast = () => {},
        onSubmit = (values={}) => {},
		autoFocusField='',
        onCustomSubmit = () => console.log(`Custom submit not set`),
    } = props

    //const [formLoaded, setFormLoaded] = useState(false)
    // const [firstSubmit, setFirstSubmit] = useState(true)
    const [focusInvalidField, setFocusInvalidField] = useState(0)

    const {
        state   : {
            [formId]:{model, currentScreen, currentGroup, totalScreens, submitAction, readOnly, formValidatedCount, fieldState, formValidationResult, hasSubmitted}
        },
        actions : {
            setForm, getModelValueByPath, setCurrentScreen, updateFormModel, resetForm,
			submitForm, setModelValueByPath, setFieldState, setFormValidationResult,
			setHasSubmitted, resetTouched,
			incFormValidatedCount
        }
    } = useOvermind('forms')

    const setScreen = (screenIndex) => {
        setCurrentScreen({formId, value:screenIndex})
    }

    useEffect(() => {
        const FormCtrl = formRef?.getChildContext()?.FormCtrl
		const formInitiator = {formId, FormCtrl, FormRef:formRef}

        setForm(formInitiator)
    }, [])

    // useEffect(() => {
    // 	if (validateOnLoad ) {
	// 		validateForm({formId})
	// 	}
    // }, [currentScreen, currentGroup])

    useEffect(() => {
    	if (1 && autoFocusField) {
    		//focus after field has been enabled (autofill prevented using disabled=true for 500ms)
    		const interval = autoFocusField=='password' ? 600 : 100 //TODO: by field.type or dom property
			setTimeout(() => {
				try {
					//document.body.click()
					document.querySelector(`[name="${autoFocusField}"]`).focus()
				} catch(e) {
					//alert(e)
				}
			}, interval)
		}
    	if (validateOnLoad ) {
			//validateForm({formId})
		}
    }, [currentScreen, currentGroup])

	useEffect(() => {
		setHasSubmitted({formId, value:0})
		resetTouched({formId})
		setFocusInvalidField(0)
	}, [])

    const getAction = () => {
        let action = document
            ?.getElementById(`_submit-btn-${formId}`)
            ?.getAttribute('submitaction')

        if (!action) {
            console.log(`Unable to find button action for ${formId}, using 'next'`)
            action = 'next'
        }
        return action
    }

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    const validateForm  = () => {
		if (formValidationTimer)
			clearTimeout(formValidationTimer)

		// console.log('validating...')
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		// console.log('associateId = ' + cloneModel?.client.associateId)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:screens}]

		for (const tab of TabList) {
			if (!tab.screens) {
				console.log('no screens')
				continue
			}

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState, hasSubmitted)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult FORM', validationResult)
		return !first
		//console.log('formValidatedCount', formValidatedCount)
	}

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(screens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change

	useEffect(() => {
		if (!formValidatedCount) {
			// console.log('formValidatedCount == undefined')
			return
		}

		if (formValidationTimer)
			clearTimeout(formValidationTimer)

		// if (hasSubmitted)
		formValidationTimer = setTimeout(() => validateForm(), 500)


	}, [formValidatedCount])

	useEffect(() => {
		if (focusInvalidField && hasSubmitted) {
			let forceSelector

			try { forceSelector = document.querySelector(`.form-field-force-invalid`) } catch(e) { }

			if (forceSelector) {
				setFocusOnSelector("form-field-force-invalid", null, targetScrollContainer)
			} else if (formValidationResult?.first) {
				const field = formValidationResult?.first?.field
				// console.log('field', field)
				setFocusOnSelectorByField(field, targetScrollContainer)
			} else if (hasSubmitted) {
				const values = _.cloneDeep(model)
				onSubmit && onSubmit(values)
			}
		}
		setFocusInvalidField(0)
		// setHasSubmitted({formId, value:false})
	}, [focusInvalidField])

    const handleSubmit = (e) => {
		e.preventDefault()
		setHasSubmitted({formId, value:hasSubmitted+1})
	}

	useEffect(() => {
		// alert(hasSubmitted)
		// if (hasSubmitted) {
			validateForm()
		// }
		if (hasSubmitted) {
			setFocusInvalidField(focusInvalidField+1)
		}
	}, [hasSubmitted])

    //Don't send the state model to the AvForm
    //const cloneModel = JSON.parse(JSON.stringify(model));
    const cloneModel = _.cloneDeep(model)
    //cloneDeep is faster, slightly more memory used
	// const cloneModel = _.cloneDeepWith(model, (value) => {
	// 	//value = 'a'
    // 	return typeof value=='string' ? value+'a' : undefined;
	// })


    return (
        <AvForm
			style={{width:'100%'}}
            ref={c => (formRef = c)}
            // onValidSubmit={handleValidSubmit}
            // onInvalidSubmit={handleInvalidSubmit}
            model={cloneModel}
            id={props.formId}
			className={`custom-form custom-form-${formId}`}
			// autoFill={'no'}
			// autoComplete="on"
            onSubmit={handleSubmit}

        >
			{children}
            <input type='submit' id={`_submit-btn-${formId}`} style={{display:'none'}}/>
        </AvForm>
    )
}

export default CustomForm;
