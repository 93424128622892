import React, {useEffect, useState} from "react"

import {
    Col, Row,
} from "reactstrap"

import { AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
//import {isValidValue} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
import { loadOptions } from "../../../../helpers/constants/remoteLists";

function FieldRadioGroup(props) {

	const {listId = '', formId} = props
    const [value, setValue] = useState(props.modelValue)

	const [options, setOptions] = useState(props.options)
	const [loading, setLoading] = useState(listId ? true : false)

	useEffect(() => {
		if (listId) {
			loadOptions(listId)
				.then((res) => {
					//console.log("OPTIONS", res)
					//const value=getModelValueByPath({formId, path:props.name})
					//alert(value)
					setOptions(res)
				})
				.finally(() => {
					setLoading(false)
				})
		}

		// if (!props.modelValue && props.defaultValue) {
		// 	setValue(props.defaultValue)
        //     props.onChange(props.name, props.defaultValue, true, true)
		// }
	}, []);

    const handleChange = (e) => {
        setValue(e.target.value)
    	//console.log('FieldRadioGroup', e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
        if (props.onBlur)
            props.onBlur(e)
    }



    // const {caption, type, inputRef, inline, formId, screenId, validation, vertical, ...rest} = props
	const rest = getValidDomProperties(props)

    return (
        <AvRadioGroup
            ref={props.inputRef || undefined}
			className={`form-control form-control-container 
				${props.center ? "radiogroup-centered" : ""}  
				${props.big ? "radiogroup-big" : ""}  
				${props.reverse ? "radiogroup-reverse" : ""}  
				${props.fieldError ? 'is-invalid' : ''}
			`}
			// readOnly={props.disabled}
            name={props.name}
            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="disabled"
			onChange={handleChange}
			value={value===undefined ? props.defaultValue : value}
			disabled={props.disabled}
			required={props.required}
            // validate={{val:() => isValidValue(value, props) }}

        >
            {props.topTitle && (
                <div className={'mt-1 mb-1 text-center'} >
                    {props.topTitle}
                </div>
            )}

            <Row className={`pt-1 radio-group-items debug`} style={props.inline ? {display:'flex', alignItems:'flex-start'} : undefined}>

            {options && options.map((option) => {
                //const optionsId = `cki-${props.name}-${option.id}`
				const label = option.info
					? <div>
						{option.caption}
						<div className="text-muted font-size-em-1">{option.info}</div>
						</div>
					: option.caption
                return (
                    <React.Fragment key={option.id}>
                        {props.vertical && <Row />}
                        <Col col={12} style={props.inline ? {flex:0} : undefined} className="debug4 radio-group-option pb-1 align-items-center">
                            <AvRadio
                                label={label}
                                value={option.id.toString()}
								onBlur={props.onBlur}
								onFocus={props.onFocus}
								disabled={props.disabled}
                            />
                        </Col>
                    </React.Fragment>
                )
            })}

            {props.info && (
                <p className={'mb-1 mt-2'} style={{paddingRight:'4em'}}>
                    <small>
                    {props.info}
                    </small>
                </p>
            )}
            </Row>

        </AvRadioGroup>
    )


}

export default FieldRadioGroup;
