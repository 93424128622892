import React from "react"
import {Spinner} from "reactstrap";

function Button(
	{
		children,
		className,
		type='button',
		colorIcon='white',
		loading=false,
		disabled=false,
		iconClass,
		color='primary',
		style={},
		...props
	}
	) {
	// console.log('props.autoFocus', props.autoFocus)
    return <button
            className={className}
            type={type || "button"}
            disabled={loading || disabled}
            style={{
            	...{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				...style,
				...colorIcon ? {
					//color : props.loading || props.disabled ? 'white' : undefined,
					//opacity : props.loading || props.disabled ? 0.5 : undefined,
					//borderColor : props.loading || props.disabled ? 'gray' : undefined,
            	} : {}
			}}
			{...props}
        >
          {loading
			  ?
				<Spinner
				  style={{ width: ".75rem", height: ".75rem", marginRight:"0.5em", marginTop: '0px' }}
				  color="dark"
				/>
			  :
			  	iconClass && <span className={`${iconClass} pe-1 debug4`}/>
          }
			{children}
        </button>


}

export default Button;
