import React, {useEffect, useState} from "react"

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";
import AnimatedDiv from "../../components/AnimatedDiv";
import {
	transitionBottomTop,
	transitionLeftRight,
	transitionOpacity, transitionOpacitySlow, transitionOpacitySlowStartFast,
	transitionRightLeft, transitionRightLeft20,
	transitionRightLeftSmall

} from "../../../components/transitions";

import AnimatedText from "../../components/AnimatedText";
import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import AnimatedContent from "../../components/AnimatedContent";
import ButtonSecondary from "../../../components/Common/Button/ButtonSecondary";

const TextInfo = (
	{title, title2, subtitle, description, className, titleClassName, style1, style2, descriptionClassName}
) => {
	return (
		<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 text-align-left text-info-container ">
			<div className="position-relative">
				<AnimatedContent
					variants={transitionOpacitySlowStartFast}
					className={`position-absolute ${descriptionClassName}`}
				>
					<div className="debug hide-in-mobile text-info-description font-family-title" >
						{description}
					</div>
				</AnimatedContent>
			</div>
			<div className={`position-relative ${titleClassName}`}>
			<AnimatedCharacters
				text={title}
				style={style1}
				fontClassName={`font-family-title font-weight-700 font-size-pt14 text-uppercase`}
				variants={transitionRightLeft}
			/>
			</div>
			{title2 && <AnimatedCharacters
				text={title2}
				splitChars='none'
				fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase "
				style={style2}
				variants={transitionRightLeft}
			/>}
			{/*<AnimatedCharacters*/}
			{/*	fontClassName="font-family-title font-weight-700 font-size-pt0 text-uppercase"*/}
			{/*	text={subtitle}*/}
			{/*	splitChars="none"*/}
			{/*	variants={transitionRightLeft}*/}
			{/*>*/}
			{/*</AnimatedCharacters>*/}
			<AnimatedCharacters
				className="hide-in-desktop"
				fontClassName="font-size-pt0 pt-3 font-family-title"
				text={description}
				splitChars="none"
				variants={transitionRightLeft}
			/>
		</AnimatedDivChilds>
	)
}

const ValuesSection = ({history, props}) => {

	return <>
		<Section className="section-values " id="seja-indicador">

			<div className="content-left">

			</div>
			<div className="content-right d-flex flex-grow-1 flex-fill d-flex flex-column justify-content-around py-5">
				<div className="debug3 px-2" >
					<TextInfo
						title={`Proteção`}
						titleClassName={'values-protection-min-height'}
						subtitle={`[Pensando no cliente do planejador]`}
						description={
							<div
								className="font-family-title "
								// style={{top:'-10rem'}}
							>
							É contar com a garantia de ter o dinheiro
							<br/>do seu cliente guardado em um dos
							<br/>maiores bancos do país. Os recursos
							<br/>saem da conta do seu cliente diretamente
							<br/>para os Superfundos Fiduc e, de lá, só
							<br/>voltam para a mesma conta de origem.
						</div>}
					/>

				</div>
				<div className="debug3 px-2" >
					<TextInfo
						title={`Proximidade`}
						titleClassName={"values-proximity-min-height"}
						subtitle={`[PENSANDO NO PLANEJADOR, VALORIZANDO O MENTOR]`}
						description={
							<div
								className="font-family-title "
								style={{minHeight:'4rem'}}
							>
								É contar com o apoio de um Mentor,
								<br/>que vai direcionar, incentivar e ajudar
								<br/>no seu crescimento como Planejador.
							</div>
						}
					/>

				</div>
				<div className="debug3 pb-4 px-2" >
					<TextInfo
						title={`ALINHAMENTO`}
						title2={`DE INTERESSES`}
						style1={{marginTop:'-.1rem'}}
						style2={{marginTop:'-.75rem'}}
						titleClassName={'values-alignment-min-height'}
						descriptionClassName={'values-alignment-desc'}
						subtitle={`[Pensando no cliente do planejador]`}
						description={<>
							É contar com a gestão profissional e 
							<br/>ativa dos investimentos do seu cliente 
							<br/>no <span className="font-weight-800 font-family-title">modelo fiduciário</span> (onde todos os
							<br/>rebates são devolvidos) e a lealdade
							<br/>de uma estrutura que não compete 
							<br/>pelos seus clientes. 
						</>}
					/>

				</div>
			</div>
		</Section>
	</>

}

export default ValuesSection

