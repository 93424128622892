import React, {useEffect} from "react"

//import LogoDucDark from "../../assets/images/logos/logo-duc-dark.svg"
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import { useInView } from "react-intersection-observer";

const AnimatedDiv = (
	{
		children,
		className,
		variants,
		threshold=1,
		controls = useAnimation(),
		...props
	}
) => {

	const [ref, inView] = useInView({
		threshold
	})

	useEffect(() => {
		// console.log(inView ? "1" : "0")
		if (inView) {
			controls.start("visible")
		} else {
			controls.start("hidden")
		}
	}, [controls, inView])

	return (
		<motion.div
			ref={ref}
			className={className}
			initial={"hidden"}
			animate={controls}
			variants={variants}
		>
			{children}
		</motion.div>
	)

}

export default AnimatedDiv

