import React, {useEffect} from "react"

import {ReactComponent as Circle} from "../../assets/images/logos/logo-circle.svg"
import {ReactComponent as CircleFi} from "../../assets/images/logos/logo-circle-fi.svg"
import {ReactComponent as TopArrow} from "../../assets/images/icons/top.svg"
import AnimatedClassName from "./AnimatedClassName";
import PageHref from "./PageHref";

const AnimatedTopArrow = (
	{
		onClick,
		theme='dark',
		children,
		className,
		...props
	}
) => {

	// const [animating, setAnimating] = useState(true)
	// const authInfo = AuthInfo()

	return (
		<AnimatedClassName className="top-arrow animated-circle" >
			<PageHref
				id={"intro"}
				setHistory={false}
				title="Ir para o início da página"
				aria-label="Ir para o início da página"
			>
				<div>
					<Circle />
					<TopArrow />
				</div>
			</PageHref>
			<div className="text-center pt-1 pb-2">TOPO</div>
		</AnimatedClassName>
	)

}

export default AnimatedTopArrow

