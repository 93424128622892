import React, {useEffect, useState} from "react"

import HomeHeader from "../components/Header";
import IntroSection from "./Intro";
import ValuesSection from "./Values";
import SimulationSection from "./Simulation";
import BePlannerSection from "./BePlanner";
import TestimonialsSection from "./Testimonials";
import FormPlannerSection from "./FormPlanner";
import FooterSection from "./Footer";
import MenuOptions from "./MenuOptions";
import Preloader from "../components/Preloader";
import { useLocation } from "react-router-dom"
import {useOvermind} from "../../overmind";
import PolicyOverlay from "../components/PolicyOverlay";
import BeAffiliate from "./BeAffiliate";

const HomePage = () => {
	const app = useOvermind('app')

	const [currentPage, setCurrentPage] = useState(null)
	const [preloading, setPreloading] = useState(true)
	const [showOptionsMenu, setShowOptionsMenu] = useState(false)
	const location = useLocation()

	useEffect(() => {
		const pageId = location.pathname.slice(1)
		// if (!preloading && currentPage!==pageId) {
			// alert(pageId)
			app.actions.moveToPage(pageId)
			//setTimeout(app.actions.moveToPage(pageId), 1000)
		// }
		setCurrentPage(currentPage)
	}, [location?.pathname, preloading])

	return <>

		<Preloader onAnimationComplete={() => setPreloading(false)}/>

		<div className={`home-container ${preloading ? "home-hidden" : "home-appear"} `}>
		{!preloading && <>
			<HomeHeader onOptionsMenuClick={() => setShowOptionsMenu(true)}/>
			<div id='page-home' className={`debug page`}>
				<IntroSection />
				<TestimonialsSection />
				<SimulationSection />
				{/* <ValuesSection /> */}
				<BeAffiliate />
				<BePlannerSection />
				<FormPlannerSection />
				<FooterSection />
				<MenuOptions
					isOpen={showOptionsMenu}
					onCloseMenuClick={() => setShowOptionsMenu(false)}
				/>
				<PolicyOverlay />
			</div>
		</>}
		</div>

		{/* <div style={{ 
			position: 'fixed',
			left: 0,
			top: 0,
			right: 0,
			height: '50vh',
			background: '#f003'
		 }}>

		</div> */}



	</>

}

export default HomePage

