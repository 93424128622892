
const _ = require('lodash')

//import states directly

export const defaultCookies = {
	consent : null,
}

export const cookies = _.cloneDeep(defaultCookies)

