import React from "react"

import {ReactComponent as LogoFi} from "../../assets/images/logos/logo-fi.svg"
import {ReactComponent as LogoDuc} from "../../assets/images/logos/logo-duc.svg"

const AnimatedLogoName = (
	{
		children,
		className,
		...props
	}
) => {


	return (
		<div className={`d-flex debug6 text-nowrap flex-nowrap ${className}`}>

			<div className="d-inline-block debug3 logo-home part-left ">
				<div>
					<LogoFi height={33} />
				</div>
			</div>
			<div className="d-inline-block debug3 logo-home part-right">
				<div>
					<LogoDuc height={33} />
				</div>
			</div>

		</div>
	)

}

export default AnimatedLogoName

