import api from "../gestora/api"

const loadedOptions = {}

const remoteLists = {

	ProfessionalOccupation : {
		url : '/carrers',
		dataId : 'id',
		dataCaption : 'name',
	},

	Country : {
		url : '/countries',
		dataId : 'id',
		dataCaption : 'name',
	},

	Function : {
		url : '/functions/all',
		dataId : 'id',
		dataCaption : 'name',
	},

	FamilyRelation : {
		url : '/clientPep/relations/1',
		dataId : 'id',
		dataCaption : 'name',
	},

	CloseRelation : {
		url : '/clientPep/relations/2',
		dataId : 'id',
		dataCaption : 'name',
	},

	Bank : {
		url : '/banks/all',
		reducer : function(map, obj) {
			map.push({id:obj.id, caption:obj.name, code:obj.code})
			return map;
		}
	},

	Associate : {
		cache:false,
		url : '/associates/short',
		reducer : function(map, obj) {
			//map.push({id:obj.id, caption:`${obj.hubspotCode} - ${obj.name}`})
			if (obj.id!=1)
				map.push({id:obj.id, caption: obj.shortName || obj.name })
			return map;
		}
	},

	// Funds : {
	// 	url : '/funds/partner/1',
	// 	dataId : 'id',
	// 	dataCaption : 'name',
	// },
	//

	// InvestmentFunds : {
	// 	url : 'admin/investments/fundtypes',
	// 	dataId : 'id',
	// 	dataCaption : 'name',
	// },

}

export const getRemoteList = (listId) => {
	return (remoteLists[listId] && loadedOptions[listId]) || null
}

export const loadOptions = (listId) => {
	const listConfig = remoteLists[listId]

	if (!listConfig) {
		console.log("List Id not registered: ", listId)
		return Promise.resolve([]);
	}

	if (loadedOptions[listId] && listConfig.cache!==false) {
		//console.log('FROM CACHE', listId)
		return Promise.resolve(loadedOptions[listId]);
	}

	//console.log("LOADING", listId)
	return api
		.get(listConfig.url)
		.then(async (response) => {

			const reducer = listConfig.reducer || function(map, obj) {
				map.push({id:obj[listConfig.dataId], caption:obj[listConfig.dataCaption]})
				return map;
			}

			loadedOptions[listId] = response.data.reduce(reducer, []);

			//console.log("LOADED", listId)
			return loadedOptions[listId]
		})
		.catch(async (error) => {
			console.log('Error loading list ', {listId, error})
			return []
		});
}

export const loadAllRemoteLists = async () => {
	console.log('Loading all remote lists...')
	const promises = []
	for (const listId in remoteLists)
		promises.push(loadOptions(listId))

	return Promise.all(promises)
}

