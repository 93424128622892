import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { AuthInfo } from "../helpers/gestora/auth"

const AuthMiddleWare = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
	<Route
		{...rest}
		render={props => {
			if (isAuthProtected && !AuthInfo()) {
				return (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				)
			}

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			)
		}}
	/>
)

AuthMiddleWare.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
}

export default AuthMiddleWare;
