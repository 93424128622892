import React, {useEffect, useRef, useState} from "react"

import { AvField } from "availity-reactstrap-validation"

import {getValidDomProperties} from "../Utils/DomProp";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

import moment from "moment"
import CustomSelect from "../../CustomSelect/CustomSelect";
import {datePeriodMapped, datePeriodOptions} from "../../../../helpers/constants/formLists";
moment.locale('pt-BR')

const rangeDateOptions = {
	mode: 'range',
	altInput: true,
	allowInput: false,
	enableTime: false,
	//altFormat: "j \\d\\e F/Y",
	altFormat: "d/m/Y",
	dateFormat: "Y-m-d",
	locale: Portuguese,
	inline: false,
	clickOpens:true,
}

function FieldDateRange(props) {

	const {formId} = props
    const [value, setValue] = useState(props.modelValue)
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    // const [value, setValue] = useState(props.modelValue)

	const ref = useRef(null);


	// useEffect(() => {
	//
	// 	console.log(`${props.name} changed `, props.modelValue)
	// 	if (props.modelValue)
	// 		setValue(props.modelValue)
	//
	// }, [props.modelValue])

    const handleChange = (date) => {
    	//alert('change')
    	//console.log('selected', date)

        if (props.onChange && date.length>1) {
			const newValue = [
				date[0] ? moment(date[0]).format('YYYY-MM-DD') : null,
				date[1] ? moment(date[1]).format('YYYY-MM-DD') : null
			]

            props.onChange(props.name, newValue, false, true)

			console.log('setValue ' + props.name, newValue)
	        setValue(newValue)
			setMenuIsOpen(false)
		}
    }

	useEffect(() => {
        document.body.addEventListener('click', () => setMenuIsOpen(false) );

        return function cleanup() {
            window.removeEventListener('click', () => setMenuIsOpen(false) );
        }
    },[]);

    const handleMenuOption = (value) => {
		handleChange(datePeriodMapped[value]?.value || [null, null])
	}

	const rest = getValidDomProperties(props)

    return (
        <>
            <AvField
	            ref={props.inputRef || undefined}
                name={props.name}
                validate={undefined}
                type="hidden"
				value={value}
			/>
			<div
				className={`form-control form-control-container   
					${props.fieldError ? 'is-invalid' : ''}
				`}
				readOnly={props.disabled}
				// onClick={() => {
				// 	if (!fp?.current?.flatpickr) return;
				// 	fp.current.flatpickr.clear();
				// }}
			>
				<div style={{display:'flex', position:'relative', width:'100%'}}>
					<div style={{
   					    width: "100%",
					    height:" 100%",
						zIndex:1,
						position:'absolute',
						display:'flex',
					}}>
						<Flatpickr
							ref={ref}
							style={{
								pointerEvents:'none'
							}}
							onOpen={() => setMenuIsOpen(false)}

							name={`ref-select2-${props.name}`}
							value={props.modelValue}
							options={rangeDateOptions}
							onChange={handleChange}
						/>
						<div
							onClick={(e) => {
								setMenuIsOpen(!menuIsOpen)
								e.stopPropagation()
							}}
							className="select-date-range-menu-options select2-selection__indicators css-15rcg96-IndicatorsContainer d-inline-flex float-end  "
						style={{}}
						>
							<span className="select2-selection__indicator-separator css-1sfptzk-indicatorSeparator"></span>
							<div className="select2-selection__indicator select2-selection__dropdown-indicator css-1b9qgl4-indicatorContainer" aria-hidden="true">
								<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
									<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
								</svg>
							</div>
						</div>
					</div>
					<CustomSelect
						isDisabled={!!props.disabled}
						// tag={[Input, CustomSelect]}
						{...rest}
						fieldError={props.fieldError}
						formId={formId}
						menuIsOpen={menuIsOpen}
						options={datePeriodOptions}


						isMulti={false}
						// onFocus={handleFocus}
						onChange={handleMenuOption}
						onBlur={null}
						required={undefined}
						// validate={{val:() => isValidNumber(value, props) }}
						validate={undefined}
					/>
				</div>
			</div>
        </>

    )
}

export default FieldDateRange;
