import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import HomePage from "../pages/HomePage";

const authProtectedRoutes = [

	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name
	{ path: "/", exact: true, component: () => <Redirect to="/" /> },
]

const publicRoutes = [

	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name

	{ path: "*", exact: true, component: HomePage },

]

export { publicRoutes, authProtectedRoutes }
