import React, {useEffect, useState} from "react"
import AnimatedLogoName from "./AnimatedLogoName";

const Preloader = (
	{
		onAnimationComplete,
		...props
	}
) => {

	const [loading, setLoading] = useState(true)
	// const authInfo = AuthInfo()

	useEffect(() => {
		if (!loading) {
			onAnimationComplete && setTimeout(() => onAnimationComplete(), 400)

		}
	}, [loading])

	useEffect(() => {
		const animationEnd = (e) => {
			if (e.animationName==='logoPartRight') {
				setLoading(false)
			}
		}

        document.body.addEventListener('animationend', animationEnd);

        return function cleanup() {
            window.removeEventListener('animationend', animationEnd);
        }
    },[]);

	return (
		<div
			// onAnimationEnd={(e) => alert(e.animationName)}
			className={`preloader ${loading ? "" : "preloader-disappear"} z-index-2 d-flex justify-content-center align-items-center expand-background animate pseudo-full-screen full-screen-div debug`}>
			<div className="d-flex flex-column ">
				<AnimatedLogoName className="name-logo-slow white"/>
			</div>
		</div>
	)

}

export default Preloader

