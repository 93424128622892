import React, {useEffect, useState} from "react"

import { AvField } from "availity-reactstrap-validation"
// import { useOvermind } from "../../../../overmind";

// import { loadOptions } from "../../../../helpers/constants/remoteLists";
import {getValidDomProperties} from "../Utils/DomProp";
import CustomSelect from "../../CustomSelect/CustomSelect";

const labelBs = 3

function FieldSelect2(props) {

	const {
		listId = '',
		formId,
		isMulti=false
	} = props
    const [value, setValue] = useState(props.modelValue || '')

	//const rest = getValidDomProperties(props)
	//const mappedValue = mappedOptions?.find(option => option.value==props.modelValue) || {id:-1, label:'none'}
	//if (props.name=='client.testfield') console.log(`${props.id} final`, {mappedValue, mappedOptions})
	// console.log(props.id, props.options)

    const handleChange = (value) => {
        if (props.onChange)
            props.onChange(props.name, value, true, true)
		//console.log('setValue', value)
        // setValue(value)
    }

    const handleBlur = (evt) => {
        if (props.onBlur) props.onBlur(evt)
		//evt.preventDefault();
    }
    useEffect(() => {
    	setValue(props.modelValue)
	}, [props.modelValue])

    return (
        <>
            <AvField
	            ref={props.inputRef || undefined}
                name={props.name}
                validate={undefined}
                type="hidden"
				value={value}
			/>
			<div
				className={`form-control form-control-container  
					${props.fieldError ? 'is-invalid' : ''}
				`}
				readOnly={props.disabled}
			>
				{/*value={value}*/}
				<CustomSelect
					isDisabled={!!props.disabled}
					// tag={[Input, CustomSelect]}
					{...props}
					name={`ref-select2-${props.name}`}
					fieldError={props.fieldError}
					listId={listId}
					formId={formId}
					fieldId={props.name}
					value={props.modelValue}

					isMulti={isMulti}
					// onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={handleChange}
					required={props.required}
					// validate={{val:() => isValidNumber(value, props) }}
					validate={props.validation}
				/>
			</div>
        </>

    )
}

export default FieldSelect2;
