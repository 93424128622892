import lStorage from "../../helpers/gestora/lStorage";

const _ = require('lodash')

export const defaultForms = {

    FormPlanner : {

        //Fprm default/initial values
        model : {
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },

}

export const forms = _.cloneDeep(defaultForms)
