import React, {useEffect} from "react"

import {ReactComponent as Circle} from "../../assets/images/logos/logo-circle.svg"
import {ReactComponent as CircleFi} from "../../assets/images/logos/logo-circle-fi.svg"
import AnimatedClassName from "./AnimatedClassName";
import PageHref from "./PageHref";

const AnimatedLogoCircle = (
	{
		theme='dark',
		children,
		className,
		...props
	}
) => {

	// const [animating, setAnimating] = useState(true)
	// const authInfo = AuthInfo()

	return (
		<AnimatedClassName className={`logo-circle animated-circle ${className || ''}`} >
			<PageHref id={'intro'} setHistory={false}>
				<Circle />
				<CircleFi />
			</PageHref>
		</AnimatedClassName>
	)

}

export default AnimatedLogoCircle

