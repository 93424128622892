import React from "react"
import {isEmptyFieldValue} from "./Validations";
import {scroller} from "react-scroll";

export function getValidDomProperties(props) {
    const {
    	caption,
		type,
		inputRef,
		inline,
		formId,
		screenId,
		validation,
		vertical,
		OnInputValueChange,
		validationInRealTime,
		screenIndex,
		validate,
		listId,
		rComponent,
		customFormatter,
		legacyValidation,
		modelValue,
		noEmptyOption,
		tabId,
		requiredMethod,
		allowNumbers,
		setFieldState,
		clickIconTrigger,
		fieldError,
		fieldIndex,
		isFirstColumn,
		isLastColumn,
		isFocused,
		isEmptyFieldValue,
		minLength,
		customError,
		md,
		sm,
		xs,
		xl,
		lg,
		...rest
    } = props

	return rest
}

let currentFocusTimeout

export const autoSelectInputValue = (e) => {
	if (currentFocusTimeout)
		clearTimeout(currentFocusTimeout)

	currentFocusTimeout = setTimeout(() => {
		try {
			e.target.select(e)
		} catch(err) {}
	} , 100)

}

export const autoSelectInputValueStart = (e) => {
	if (currentFocusTimeout)
		clearTimeout(currentFocusTimeout)

	currentFocusTimeout = setTimeout(() => {
		try {
			const input = e.target
			if (input.setSelectionRange) {
				input.setSelectionRange(0, 0);
			} else if (input.createTextRange) {
				const range = input.createTextRange();
				range.collapse(true)
				range.moveEnd('character', 0)
				range.moveStart('character', 0)
				range.select();
			}
		} catch(err) {}
	} , 100)

}

export const getFieldInputElement = (field) => {
	let input
	try {
		if (field.type==='select2' || field.type==='select2multi' ) {
			input = document.querySelector(`[data-id="form-control-${field.id}"] .select2-selection__input INPUT[type="text"]`)
		} else {
			input = document.querySelector(`[name="${field.invalidFocusInputName || field.id}"]`)
		}
	} catch(e) { }
	return input
}

export const setFocusOnSelectorByField = (field, targetScrollContainer) => {
	try {

		const firstErrorFieldName = field.id
		const input = getFieldInputElement(field)

		if (firstErrorFieldName && input) {
			//console.log(`form-control-${firstErrorFieldName}`)
			const selector = field.invalidScrollSelector || `form-control-${firstErrorFieldName}`
			// alert(`form-control-${firstErrorFieldName}`)
			// console.log(selector)
			setTimeout(() => setFocusOnSelector(selector, input, targetScrollContainer), 100)
		}
	} catch(e) {
		console.log('onInvalidSubmit: not able to scroll', e)
	}

}

export const hideActiveModal = (cb) => {
	try {
		document.querySelector(`#root>div>div[tabindex="0"]`).click()
		if (cb)
			setTimeout(cb, 500)
		return true
	} catch(e) {
		return false
	}
}

export const setFocusOnSelector = (selector, input=null, containerId='main-content', offset=-64) => {
	//alert('setFocusOnSelector')
	const duration = 500
	try {
		scroller.scrollTo(selector, {
			containerId : containerId,
			smooth: true,
			offset,
			duration,
		})
	} catch(e) {
		try {
			scroller.scrollTo(selector, {
				smooth: true,
				offset,
				duration,
			})
		} catch(e) {}
	}
	if (input) {

		setTimeout(() => {
			try {input.focus()} catch(e) {}
			try {
				scroller.scrollTo(selector, {
					containerId : containerId,
					smooth: true,
					offset,
					duration,
				})
			} catch(e) {
				try {
					scroller.scrollTo(selector, {
						smooth: true,
						offset,
						duration,
					})
				} catch(e) {}
			}
		}, duration+(500/16) )
	}

}

