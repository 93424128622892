import React from "react"
import Button from "./Button";

function ButtonPrimary (
	{
		caption='',
		fillWidth=false,
		className,
		height="32pt",
		width="60px",
		...props
	}
	) {
    return <Button
			className={`btn f-btn f-btn-primary  ${fillWidth && "w-100"} ${className}`}
			style={{height}}
			{...props}
		>
			{caption}
		</Button>

}

export default ButtonPrimary;
