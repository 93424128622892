import React, { useEffect, useState } from "react"
import AnimatedContent from "pages/components/AnimatedContent";
import AnimatedDivChilds from "pages/components/AnimatedDivChilds";
import Section from "pages/components/Section";
import SectionFooterText from "pages/components/SectionFooterText";
import { transitionLeftRight, transitionOpacitySlowStartFast } from "components/transitions";
import AnimatedCharacters from "pages/components/AnimatedCharacters";

export default function BeAffiliate({ history, props }) {
  return (
    <Section className="section-beaffiliate" id="seja-indicador">

      <div className="content-left d-flex flex-column">
        <div className="debug2 flex-grow-1 flex-fill d-flex justify-content-center text-align-right content-left-text">

        </div>

        <div className="debug2 flex-grow-0">
          <div className="fiduc-slogan-bottom ">
            <SectionFooterText />
          </div>
        </div>
      </div>
      <div className="content-right font-family-title"      >
        <div
          className="d-flex flex-grow-1 flex-fill d-flex "
          style={{ paddingTop: 55 }}
        >

          <AnimatedDivChilds staggerChildren={.15} >
            <AnimatedCharacters
              text={"Você pediu, nós ouvimos:"}
              splitChars={'none'}
              fontClassName="font-family-title font-size-view2"
              variants={transitionLeftRight}
            />

            <AnimatedCharacters
              text={"Programa de Indicação de Novo Planejador!"}
              splitChars={'none'}
              fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase"
              variants={transitionLeftRight}
            />

            <AnimatedCharacters
              text={"Como funciona?"}
              splitChars={'none'}
              fontClassName="font-family-title font-size-view2 mt-5"
              variants={transitionLeftRight}
            />
            <AnimatedCharacters
              text={"Para cada Planejador que se inscrever usando seu link de indicação, você receberá um percentual da receita gerada durante seus 3 primeiros anos."}
              splitChars={'none'}
              fontClassName="font-size-pt0"
              variants={transitionLeftRight}
            />
          </AnimatedDivChilds>          
        </div>
      </div>
    </Section>
  )
}