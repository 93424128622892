import React from "react"
const _ = require('lodash')
import moment from "moment"
moment.locale('pt-BR')

export const certificationOptions = [
    {id:'1', caption:"CPA-10"},
    {id:'2', caption:"CPA-20"},
    {id:'3', caption:"CEA"},
    {id:'4', caption:"CFP"},
    {id:'7', caption:"Não Possuí"},
    {id:'-1', caption:"Outra"},
]

export const occupationAreaOptions = [
    {id:'1', caption:"Bancos e Financeiras"},
    {id:'2', caption:"Investimentos"},
    {id:'3', caption:"Corretora de Seguros e Seguradoras"},
    {id:'4', caption:"Varejo"},
    {id:'5', caption:"Outras"},
]

export const schoolingOptions = [
    {id:'1', caption:"Ensino Médio Incompleto"},
    {id:'2', caption:"Ensino Médio Completo"},
    {id:'3', caption:"Ensino Superior"},
    {id:'4', caption:"Pós Graduação, Mestrado ou Doutorado"},
]

export const genderRecrutOptions = [
	{id:'M', caption:"Masculino"},
	{id:'F', caption:"Feminino"},
	{id:'M', caption:"Prefiro não declarar"},
]

export const yesNoOptions = [
	{ id: '1', caption: 'Sim' },
	{ id: '0', caption: 'Não' },
];

export const statesBrOptions = [
	{ id: 'AC', caption: 'AC' },
	{ id: 'AL', caption: 'AL' },
	{ id: 'AM', caption: 'AM' },
	{ id: 'AP', caption: 'AP' },
	{ id: 'BA', caption: 'BA' },
	{ id: 'CE', caption: 'CE' },
	{ id: 'DF', caption: 'DF' },
	{ id: 'ES', caption: 'ES' },
	{ id: 'GO', caption: 'GO' },
	{ id: 'MA', caption: 'MA' },
	{ id: 'MG', caption: 'MG' },
	{ id: 'MS', caption: 'MS' },
	{ id: 'MT', caption: 'MT' },
	{ id: 'PA', caption: 'PA' },
	{ id: 'PB', caption: 'PB' },
	{ id: 'PE', caption: 'PE' },
	{ id: 'PI', caption: 'PI' },
	{ id: 'PR', caption: 'PR' },
	{ id: 'RJ', caption: 'RJ' },
	{ id: 'RN', caption: 'RN' },
	{ id: 'RO', caption: 'RO' },
	{ id: 'RR', caption: 'RR' },
	{ id: 'RS', caption: 'RS' },
	{ id: 'SC', caption: 'SC' },
	{ id: 'SE', caption: 'SE' },
	{ id: 'SP', caption: 'SP' },
	{ id: 'TO', caption: 'TO' }
]

export const datePeriodOptions = [
	{ id: 'today', caption: 'Hoje', value:[
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
	{ id: 'yesterday', caption: 'Ontem', value:[
			//moment().startOf('month').format('YYYY-MM-DD'),
			moment().subtract(1, 'days').format('YYYY-MM-DD'),
			moment().subtract(1, 'days').format('YYYY-MM-DD'),
		]},
	{ id: 'thisWeek', caption: 'Semana Atual', value:[
			moment().startOf('week').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
	{ id: 'thisMonth', caption: 'Mês Atual', value:[
			moment().startOf('month').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
	{ id: 'thisYear', caption: 'Desde o início do ano', value:[
			moment().startOf('year').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
		]},
]

export const datePeriodMapped = _.keyBy(datePeriodOptions, 'id')

