import React, {useEffect} from "react"
import AnimatedLogoName from "./AnimatedLogoName";
import useScrollPosition from "../../helpers/uses/scrollposition";

import {ReactComponent as LogoIcon} from "../../assets/images/logos/fiduc-icon.svg"

import ButtonMenu from "./ButtonMenu";
import PageHref from "./PageHref";
import ButtonSecondary from "../../components/Common/Button/ButtonSecondary";
import {useOvermind} from "../../overmind";
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import {transitionAmplify} from "../../components/transitions";
import AnimatedDiv from "./AnimatedDiv";
import AnimatedContent from "./AnimatedContent";

const HomeHeader = (
	{
		children,
		className,
		onOptionsMenuClick,
		...props
	}
) => {

	const app = useOvermind('app')
	const scrollPosition = useScrollPosition()

	const registerButtonControls = useAnimation()

	useEffect(() => {
		console.log('introRegisterButtonVisible', app.state.introRegisterButtonVisible)
		registerButtonControls.start(app.state.introRegisterButtonVisible ? "hidden" : "visible")
	}, [registerButtonControls, app.state.introRegisterButtonVisible])

	return (
		<div className={`debug4 section header-container text-white z-index-4 ${scrollPosition.isOnTop ? "" : "sticky"}`} >
			<div className="debug1 content-left z-index-1 position-relative">
				<div
					className="header-logo debug4 d-flex flex-grow-1 flex-fill "
					style={{height:'32px', minWidth:'32px'}}
				>
					<span >
						<PageHref className="link-header" id={'intro'} setHistory={false}>
							<LogoIcon height={42} width={42} />
						</PageHref>
					</span>
				</div>
			</div>
			<div className="debug1 d-flex flex-row content-right z-index-1" >
				<div className="header-logo-name flex-grow-0">
					{/* <PageHref className="link-header" id={'intro'} setHistory={false}>
					</PageHref> */}
					<a href="https://fiduc.com.br" className="link-header">
						<AnimatedLogoName className="name-logo-fast "/>
					</a>
				</div>
				<div
					className="header-menu-container debug6 d-flex flex-row flex-grow-1 flex-fill   "
					// style={{height:'100%'}}
				>
					<div className="me-3 d-flex flex-row debug menu-items justify-content-around ">
						<div className="hide-in-mobile font-size-pt0 d-flex flex-row" style={{ columnGap: 32 }} >
							<MenuItem caption="Seja Indicador" id="seja-indicador" />
							<MenuItem caption="Ganhos" id="simular-seus-ganhos" />
							<MenuItem caption="Depoimentos" id="depoimentos" />
						</div>
						<AnimatedContent
							className="d-flex flex-row justify-content-center align-items-center "
							controls={registerButtonControls}
							initial={'hidden'}
							variants={transitionAmplify}
						>
							<PageHref Tag={ButtonSecondary}
								caption="Seja Fiduc"
								// fillWidth={true}
								height={'2rem'}
								className="hide-in-mobile header-register-button font-size-rem-0 mx-4"
							  	id={'seja-um-planejador'}
								// onClick={e => app.actions.moveToPage('seja-um-planejador')}
							/>
							<PageHref Tag={ButtonSecondary}
								caption="Seja Fiduc"
								// fillWidth={true}
								height={'1.5rem'}
								className="hide-in-desktop font-size-rem-4 p-0 px-1"
								id={'seja-um-planejador'}
							/>
						</AnimatedContent>
						<div className="debug5 hide-in-desktop d-flex p-1 ps-3">
							<ButtonMenu onClick={onOptionsMenuClick}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}

const MenuItem = ({caption, id, href}) => {

	return (
		<PageHref className="debug5 link-header font-size-px12" id={id}>{caption}</PageHref>
	)
}

export default HomeHeader

