import React, {useEffect, useState} from "react"
import {ReactComponent as BackButton} from "../../../assets/images/icons/back-button.svg";

const IconClass = ({className, iconStyle, ...props}) => {
	return <i
		className={className}
		style={iconStyle}
		{...props}
	/>
}

function ButtonIcon (
	{
		className,
		iconClass='fas fa-user',
		iconStyle = undefined,
		Icon,
		width,
		height,
		disabled,
		title,

		reverse=false,
		column=false,
		caption=null,
		hoverColor="var(--bs-fiduc-green)",
		color="var(--bs-fiduc-black)",
		onClick,
		...props
	}
	) {

	const [isHovered, setIsHovered] = useState(false)
	const [layoutClass, setLayoutClass] = useState(false)
	const Component = Icon || IconClass

	useEffect(() => {
		if (column) {
			setLayoutClass(!reverse ? "flex-column-reverse" : "flex-column")
		} else {
			setLayoutClass(!reverse ? "flex-row-reverse" : "flex-row")
		}
	}, [reverse, column])

    return (
    	<button
			onMouseOver={() => setIsHovered(true)}
			onMouseOut={() => setIsHovered(false)}
			onTouchStart={() => setIsHovered(true)}
			onTouchEnd={() => setIsHovered(false)}
			className={`f-btn-icon debug2 cursor-pointer d-flex ${layoutClass} justify-content-center align-items-center ${className}`}
			onClick={onClick}
			title={title}
			disabled={disabled}
			type="button"
		>
			{caption &&
				<div
					style={{
						color:isHovered && !disabled ? hoverColor : color
					}}
					className="debug flex-fill flex-grow-1 px-1 justify-content-center align-items-center" >
					{caption}
				</div>
			}
			{Component &&
				<div className="debug4 f-btn-icon-text flex-grow-0 d-flex justify-content-center align-items-center">
					<Component
						style={{color:isHovered && !disabled ? hoverColor : color, ...iconStyle}}
						width={width}
						height={height}
						className={`${iconClass}`}
						{...props}
					/>
				</div>
			}
		</button>
	)
}

export default ButtonIcon;
