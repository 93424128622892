
//import {environment} from './configuration'

const authUserStorageName = 'FiducGestora:authUser'
const authNUserStorageName = 'FiducGestora:authNUser'
const authOtherAccountsStorageName = 'FiducGestora:otherAccounts'

import lStorage from "./lStorage"

export const Login = (info) => {
	return lStorage.set(authUserStorageName, info)
}

export const Logout = () => {
	lStorage.set(authNUserStorageName, '')
	lStorage.set(authOtherAccountsStorageName, '')
	return lStorage.set(authUserStorageName, '')
}

export const AuthInfo = () => {
	return lStorage.get(authUserStorageName)
}

export const AuthOtherAccountsInfo = () => {
	return lStorage.get(authOtherAccountsStorageName)
}

export const SetAuthOtherAccountsInfo = (accounts) => {
	return lStorage.set(authOtherAccountsStorageName, accounts)
}

export const AuthNInfo = () => {
	return lStorage.get(authNUserStorageName)
}

export const LoginAuthN = (info) => {
	return lStorage.set(authNUserStorageName, info)
}

