import React, {useEffect, useState} from "react"

import {
    Col, Row,
} from "reactstrap"

import { AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation"
//import {isValidOptions} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
import { loadOptions } from "../../../../helpers/constants/remoteLists";

const _ = require('lodash')

function FieldCheckAccept(props) {

	const {listId = '', formId} = props
    const [value, setValue] = useState('')
	const [options, setOptions] = useState(props.options)
	const [loading, setLoading] = useState(listId ? true : false)

	useEffect(() => {
		if (listId) {
			loadOptions(listId)
				.then((res) => {
					//console.log("OPTIONS", res)
					//const value=getModelValueByPath({formId, path:props.name})
					//alert(value)
					setOptions(res)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, []);

    //const [value, setValue] = useState([])
    const handleChange = (e) => {
    	//console.log(props.inputRef)
        //setValue(e.target.value)
    	// console.log('FieldCheckGroup', e.target.value)
		//console.log('e.target.value', e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.checked ? "1" : "", true, true)
    }

    // const {caption, type, inputRef, formId, screenId, vertical, validation, ...rest} = props
	const rest = getValidDomProperties(props)

	// const thevalue = _.cloneDeep(props.modelValue)
	// console.log('thevalue', thevalue)
    return (
        <AvCheckboxGroup
            ref={props.inputRef || undefined}
			className={`form-control form-control-container  my-2 pb-4
				${props.fieldError ? 'is-invalid' : ''}
			`}
            name={props.name}
            label={null}
            // errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="disabled"
            onChange={handleChange}
			disabled={props.disabled}
			required={props.required}
            //validate={{val:() => isValidOptions(value, props) }}
        >
            <Row className="pt-1" style={props.inline ? {display:'flex', alignItems:'flex:start'} : undefined}>
				{props.vertical && <Row />}
				<Col col={12} style={props.inline ? {flex:0} : undefined} >
					<AvCheckbox
						className="align-self-start mt-2"
						label={props.acceptCaption}
						value={props.modelValue}
						onBlur={props.onBlur}
						onFocus={props.onFocus}
						disabled={props.disabled}
					/>
				</Col>
            </Row>
        </AvCheckboxGroup>
    )


}

export default FieldCheckAccept;
