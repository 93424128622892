import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"

import { Switch, BrowserRouter as Router, useLocation } from "react-router-dom"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import AuthMiddleWare from "./routes/AuthMiddleWare"

// layouts Format
import Layout from "./components/Layout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/fiduc/fiduc.scss"

// import {AuthInfo} from "./helpers/gestora/auth";
import {AnimatePresence} from "framer-motion/dist/framer-motion";
import {environment} from "./configuration";
import CheckAppVersion from "./components/Common/CheckAppVersion";


const App = props => {

	// const location = useLocation()

	useEffect(() => {
		if (environment!=='production') {
			const handleKeyPress = (e) => {
				if (e.keyCode==39) { // 39 = ' (single quote)
					document.body.classList.toggle('debug-on')
				}
			}
			document.body.addEventListener('keypress', handleKeyPress );
			return () => document.body.removeEventListener('keypress', handleKeyPress );
		}
    },[])

  return (
	  <React.Fragment>
		  <AnimatePresence exitBeforeEnter >
			  <Switch location={location} key={location.pathname}>
				  {publicRoutes.map((route, idx) => (
					  <AuthMiddleWare
						  path={route.path}

						  layout={Layout}
						  component={route.component}
						  key={location.pathname}
						  isAuthProtected={false}
						  exact
					  />
				  ))}

				  {authProtectedRoutes.map((route, idx) => (
					  <AuthMiddleWare
						  path={route.path}
						  layout={Layout}
						  component={route.component}
						  key={location.pathname}
						  isAuthProtected={true}
						  exact
					  />
				  ))}
			  </Switch>
		  </AnimatePresence>
	  </React.Fragment>

  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default App
