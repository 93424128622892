import React, {useEffect} from "react"

const ButtonMenu = (
	{
		children,
		className,
		onClick,
		...props
	}
) => {

	// const [animating, setAnimating] = useState(true)
	// const authInfo = AuthInfo()

	return (
		<button
			className={`button-menu ${className || ''}`}
			type="button" title="Abrir menu"
			aria-label="Abrir menu"
			onClick={onClick}
		>
			<span />
			<span />
			<span />
		</button>
	)

}

export default ButtonMenu

