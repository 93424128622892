import React, {useEffect, useMemo} from "react"

//import LogoDucDark from "../../assets/images/logos/logo-duc-dark.svg"
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import { useInView } from "react-intersection-observer";
import AnimatedCharacters from "./AnimatedCharacters";

const AnimatedText = (
	{
		children,
		className,
		text,
		variants,
		line=0,
		splitChars,
		...props
	}
) => {

	const controls = useAnimation()
	const [ref, inView] = useInView({
		threshold: 1
	})

	useEffect(() => {
		if (inView) {
			controls.start("visible")
		} else {
			controls.start("hidden")
		}
	}, [controls, inView])

	const container = useMemo(() => {
		return {
			visible: {
				transition: {
					staggerChildren: 0.025,
					delayChildren:line*(0.5),
					// delay : line*1,
				}
			}
		}
	}, [line])



	return (
		<div
			ref={ref}
			className={`debug3 ${className}`}
		>
			<motion.div
				// className="position-relative"
				initial={"hidden"}
				animate={controls}
				variants={container}
			>
				<AnimatedCharacters
					text={text}
					variants={variants}
					splitChars={splitChars}
				/>
			</motion.div>
		</div>
	)

}

export default AnimatedText

