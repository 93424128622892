import React, {useState} from "react"

import { AvField, AvFeedback, InputGroupAddon  } from "availity-reactstrap-validation"
import {clearPonctuation} from "../../../../helpers/utils";
import {isValidValue} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
import FieldText from "./Text";

export const validate = (value) => {
    return value && value.toString().trim() ? true : false
}

function FieldHidden(props) {
	return <FieldText {...props} type={"hidden"}/>
}

export default FieldHidden;
