import React, {useEffect} from "react"

//import LogoDucDark from "../../assets/images/logos/logo-duc-dark.svg"
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import {transitionAnimatedTextDefault} from "../../components/transitions";

// Word wrapper
const Wrapper = (props) => {
  // We'll do this to prevent wrapping of words using CSS
  //return <span className="word-wrapper">{props.children}</span>;
  return <span style={{ marginRight: 10, display: 'inline-block' }}>{props.children}</span>;
};

const getSplitWords = (text, splitChars='chars') => {
	//  Split each word of props.text into an array
	if (splitChars==='none') {
		return [[text]]
	}

	const splitWords = text.split(" ")

	// Create storage array
	const words = []

	// Push each word into words array
	for (const [, item] of splitWords.entries()) {
		words.push(splitChars==='chars' ? item.split("") : [item]);
	}

	// Add a space ("\u00A0") to the end of each word
	words.map((word, index) => {

		return index<words.length ? word : word.push("\u00A0");
	})

	return words
}

const AnimatedCharacters = (
	{
		text="",
		Tag="div",
		splitChars=false,
		variants = transitionAnimatedTextDefault,
		className,
		style,
		fontClassName,
		...props
	}
) => {

	const words = getSplitWords(text, splitChars)

	return (
		<Tag className={className} style={style}>
			{words.map((word, index) => {
				return (
					// Wrap each word in the Wrapper component
					<Wrapper key={index}>
						{words[index].flat().map((element, index) => {
							return (
								<span
									style={{
										overflow: "hidden",
										display: "inline-block"
									}}
									key={index}
								>
									<motion.span
										style={{ display: "inline-block" }}
										variants={variants}
										// initial="hidden"
										// animate={'visible'}
										className={fontClassName}
									>
										{element}
									</motion.span>
								</span>
							)
						})}
					</Wrapper>
				)
			})}
			{/* {} */}
		</Tag>
	)

}

export default AnimatedCharacters;
