//export const environment = "production"
//export const environment = "localhost"
//export const environment = "homolog"
export const environment = process?.env?.REACT_APP_ENVIRONMENT || 'localhost'

export const isLocalhost = environment==='localhost' || environment==='lan'

if (0 && environment==='development') {
	window.onerror = function(msg, src, lineno, colno, error) {
		alert(`
			\nmsg: ${msg}		
			\nsrc: ${src}		
			\nlineno: ${lineno}		
			\ncolno: ${colno}		
			\nerror: ${error}		
			\nmessage: ${error?.message}		
		`)
	}

	window.onunhandledrejection = (e) => {
		alert(`Error Message: ${e?.reason?.message}`)
	}
}

import {
	deviceDetect as deviceDetectFunc,
	isIOS,
	isSafari,
	isMobile,
	isDesktop,
	isTablet,
	isMacOs,
	isSmartTV,
	isConsole,
	isWearable,
	isMobileSafari,
	isChromium,
	isMobileOnly,
	isAndroid,
	isWinPhone,
	deviceType,
	osName,
	osVersion,
	browserVersion,
	fullBrowserVersion,
	browserName,
	isWindows,
	mobileModel,
	getUA,
} from 'react-device-detect'

export const isRNWebView = window.isRNWebView || false
export const RNBiometryType = window.RNBiometryType

export const isInStandaloneMode =
	window.matchMedia('(display-mode: standalone)').matches
	|| window.navigator.standalone
	|| document.referrer.includes('android-app://');

const deviceDetect = deviceDetectFunc()

export const WebAuthN = {available:false}

if (window.PublicKeyCredential) {
	window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
		.then(available => WebAuthN.available = available)
		.catch(() => {});
}

export const Device = {
	isRNWebView,
	RNBiometryType,

	isIOS, isSafari, isMobile, isInStandaloneMode, isDesktop, isTablet,
	isWindows,
	isMacOs,
	WebAuthN,
	deviceType : isTablet ? "tablet" : deviceType,
	osName,
	osVersion,
	browserVersion,
	fullBrowserVersion,
	browserName,
	mobileModel,
	deviceDetect,
}
export const JSInterface = window.MyJSInterface

export const reportDeviceInfo = {
	browserVersion:fullBrowserVersion || "",
	deviceName : browserName || mobileModel || "",
	deviceType: Device.deviceType || "",
	mobileModel : (mobileModel==='none' ? "" : mobileModel) || "",
	osName : osName || "",
	osVersion : osVersion || "",
	pagePlatform : isInStandaloneMode ? "pwa" : isRNWebView ? "native" : "web",
	userAgent : getUA || "",
	// deviceDetect
}


//adb reverse tcp:6662 tcp:6662
//export const isSafari = /Safari/i.test(navigator.userAgent) && /Macintosh/i.test(navigator.userAgent)



//alert(isIOS)

// const fiducUrls = {
// 	production : 'https://api2.fiduc.com.br/fiduc',
// 	production2 : 'https://api2.fiduc.com.br/fiduc',
// 	homolog : 'https://api2-hml.fiduc.com.br/fiduc',
// 	//localhost : isRNWebView ? 'http://10.0.2.2:3333/fiduc' : "http://localhost:3333/fiduc"
// 	localhost : `http://${isRNWebView ? "10.0.2.2" : window.location.hostname}:3333/fiduc`,
//
// }

// alert(window.location.hostname)

export const fiducUrl = process?.env?.REACT_APP_FIDUC_URL
// export const fiducUrl = environment === 'localhost'
// 	? `http://${isRNWebView ? "10.0.2.2" : window.location.hostname}:3333/fiduc`
// 	: process?.env?.REACT_APP_FIDUC_URL
// alert(fiducUrl)

export const defaultPageTitle = environment==='production'
		? 'Fiduc'
		: `[${environment}] Fiduc`


export const acceptUploadTypeImage  = "image/png, image/jpeg, image/jpg, image/bmp"
export const acceptUploadTypeImageAndPdf  = "image/png, image/jpeg, image/jpg, image/bmp, application/pdf"

export const defaultFiducAcceptFiles = acceptUploadTypeImageAndPdf

// alert(isMobile)

console.log('environment', {environment, fiducUrl, userAgent:navigator.userAgent, reportDeviceInfo, Device})

