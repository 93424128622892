import React, {useEffect} from "react"

//import LogoDucDark from "../../assets/images/logos/logo-duc-dark.svg"
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import {transitionAnimatedTextDefault} from "../../components/transitions";

const AnimatedContent = (
	{
		Tag="div",
		variants = transitionAnimatedTextDefault,
		className,
		style,
		controls,
		children,
		initial,
		...props
	}
) => {

	const MotionTag = motion[Tag] || motion.div
	return (
		<MotionTag
			variants={variants}
			initial={initial}
			animate={controls}
			className={className}
			style={style}
		>
			{children}
		</MotionTag>
	)

}

export default AnimatedContent;
