import React, {useEffect, useState} from "react"

import InputMask from "react-input-mask";
import { AvField, AvFeedback } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
//import { isCpf, isCnpj } from '../../../../helpers/validation_helper'
import {isCpfFormat, isValidCpfCnpj} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
// import { parseDocument } from '../../../../helpers/parser_helper'

function FieldCpfCnpj(props) {

    const [value, setValue] = useState(props.modelValue)
    const [isCpf, setIsCpf] = useState(false)

	useEffect(() => {
		setIsCpf(isCpfFormat(value))
	}, [value])

    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value, true, true)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)

    return (
        <AvField
			//label={'isCpf'+isCpf}
            ref={props.inputRef || undefined}
            className="form-control"
            mask={isCpf ? "999.999.999-999999999" : "99.999.999/9999-99"}
            maskChar={value ? "" : null}
            tag={[Input, InputMask]}
            {...rest}
            onChange={handleChange}
            type="text"
			inputMode="decimal"
            autoComplete="no"
			required={props.required}
            // validate={{val:() => isValidCpfCnpj(value, props) }}
            validate={props.validation}
        />

    )
}

export default FieldCpfCnpj;
