
import axios from 'axios';
import {Device, environment, fiducUrl} from '../../configuration'
import {AuthInfo, Logout} from "./auth";

// const https =  require('https');
export const apiErrors = {
	ERR028: 'A senha digitada está incorreta, por favor tente novamente.',
	ERR063: 'O número de documento informado já está cadastrado.',
	ERR027: 'O número de documento informado não foi encontrado.',
	ERR084: 'A senha escolhida não pode ser a mesma que a senha anterior.',
	ERR158: 'Já existe um formulário de registro para este parceiro.',
	ERR118: 'RefistrationForm não encontrado',
	ERR133: 'Não é possível assinar o formulário de registro.',
	ERR135: 'Assinatura de formulário de registro inválida.',
	ERR140: 'O CPF do co-titular da conta não pode ser o mesmo do titular',
};

export const getApiErrorCode = (error) => {
	return error?.response?.data[0]?.code
		|| error?.response?.data?.message
		|| error?.response?.data?.error?.message
		|| error?.response?.data?.error?.code
}

export const getApiError = (error, code) => {
	const errCode = getApiErrorCode(error)
	console.log('getApiError', error)
	return environment==='production'
		? (errCode && apiErrors[errCode])
		: (errCode && apiErrors[errCode]) || JSON.stringify(error?.response?.data) || JSON.stringify(error) || error
}

const api = axios.create({
	baseURL: fiducUrl,
	proxy: false,
	crossDomain: true,
	//withCredentials: true,
	httpsAgent: { rejectUnauthorized: true },
	timeout: 60000*10,
});

/*
const api = wrapper(http, {
  maxCacheSize: 15
}) */

/*
api.__addFilter(/users\/car-brands/)
api.__addFilter(/users\/car-models/)
api.__addFilter(/users\/car-colors/)
*/
/*

*/

export const sleep = (ms) => {
	return function(x) {
		return new Promise(resolve => setTimeout(() => resolve(x), ms));
	};
}


api.interceptors.request.use(

	async (request) => {
		const loginInfo = AuthInfo();

		request.headers.Accept = 'application/json';

		if (loginInfo && loginInfo.token) {
			if (!request.headers.Authorization)
				request.headers.Authorization = loginInfo.token;

			if (!request.headers["Login-ID"])
				request.headers["Login-ID"] = loginInfo.loginId;

			request.headers["Device-Type"] = Device.deviceType;
		}

		return request;
	},

	async (error) => {
		console.log('request-error', error)

		return error;
	}


);

api.interceptors.response.use(
	(response) => response,
	async (error) => {

		if (error.response) {
			// Request made and server responded
			console.error('Response Error: ', error.response);
		} else if (error.request) {
			// The request was made but no response was received
			// console.log('Request error: ', { error, request: error.request });
			console.error('Request error: ', error);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error', error.message);
		}

		if (error?.response?.status === 401 || error?.response?.status === 405) {
			const authInfo = AuthInfo()
			await Logout()
			if (authInfo?.token) {
				alert('Sessão expirada!');
				window.location = '/login'
			}
			return new Promise(() => {}); //Cancel chaining
			// return Promise.reject();
		} else {
			return Promise.reject(error);
		}
	}
);

export default api;
