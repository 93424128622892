import React, { useEffect, useState } from "react"

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";

import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import {
	transitionAmplify,
	transitionBottomTop, transitionLeftRight, transitionOpacity,
	transitionOpacitySlowStartFast,
	transitionRightLeft, transitionRightLeftSmall
} from "../../../components/transitions";
import AnimatedContent from "../../components/AnimatedContent";
import AnimatedLogoCircle from "../../components/AnimatedLogoCircle";
import AnimatedTopArrow from "../../components/AnimatedTopArrow";
import { useOvermind } from "../../../overmind";


const MenuItem = (
	{
		title,
		href = '#',
		className = 'link-dark',
	}
) => {
	return <>
		<a href={href} className={`debug4 ${className}`}>
			<AnimatedCharacters
				text={title}
				splitChars={'none'}
				fontClassName="debug5 font-weight-500 font-size-pt0 text-uppercase"
				variants={transitionRightLeft}
			/>
		</a>
	</>
}

const SocialIcon = (
	{
		title,
		href = '#',
		className = 'link-dark',
		iconClass = 'fab fa-instagram',
	}
) => {
	return <>
		<a
			href={href}
			className={`debug4 ${className}`}
			title={title}
			target={"_blank"}
			rel="noreferrer"
		>
			<AnimatedContent
				className="debug5 font-weight-500 font-size-pt4 text-uppercase text-align-center"
				variants={transitionBottomTop}
			>
				<span className={`${iconClass} `} style={{ minWidth: '24pt' }} />
			</AnimatedContent>
		</a>
	</>
}

const FooterSection = ({ history, props }) => {

	const app = useOvermind('app')

	return <>
		<Section className="section-footer " id="duvidas">

			<div className="content-left d-flex justify-content-center align-items-start info-text">
				<AnimatedLogoCircle />
			</div>
			<div className="debug2 content-right d-flex flex-grow-1 flex-fill d-flex flex-column  ">
				<div className="debug3 hide-in-desktop flex-grow-0 d-flex justify-content-center align-items-center">
					<AnimatedLogoCircle />
				</div>
				<div className=" debug1 d-flex flex-fill flex-row flex-grow-1">
					<AnimatedDivChilds
						staggerChildren={.15}
						className="menu-container d-flex flex-column flex-grow-0 text-align-left text-info-container"
					>
						<MenuItem
							title={'Quem somos?'}
							href={'https://fiduc.com.br/sobre-investimentos-tripla-filtragem-alinhamento-de-interesses-comite-de-investimentos/'}
						/>
						<MenuItem
							title={'Investimentos'}
							href={'https://fiduc.com.br/investimentos-fiduc-jeito-fiduc-de-investir/'}
						/>
						<MenuItem
							title={'FI Mídia'}
							href={'https://fiduc.com.br/fi-midia-fiduc-investimentos-e-proximidade/'}
						/>
						<MenuItem
							title={'Contato'}
							href={'https://fiduc.com.br/quem-somos-fiduc-investimentos-e-proximidade/'}
						/>
					</AnimatedDivChilds>
					<div className="debug2 d-flex flex-column  flex-grow-1 flex-fill justify-content-center align-items-center debug1">

					</div>
					<div className="hide-in-mobile debug2 d-flex flex-grow-1 flex-fill justify-content-center align-items-start debug1">
						<div className="">
							<AnimatedTopArrow />
						</div>
					</div>
				</div>
				<div className="social-menu-container debug3 d-flex flex-column no-select pt-4 ">
					<div className="text-uppercase">Siga Fiduc</div>
					<AnimatedDivChilds staggerChildren={.15} className="d-flex flex-row ">
						<SocialIcon
							title={'YouTube'}
							iconClass={"fab fa-youtube font-size-pt4"}
							href={`https://www.youtube.com/c/Fiducoficial`}
						/>
						<SocialIcon
							title={'Linkedin'}
							iconClass={"fab fa-linkedin-in font-size-pt4"}
							href={`https://www.linkedin.com/company/fiduc-brasil/`}
						/>
						<SocialIcon
							title={'Instagram'}
							iconClass={"fab fa-instagram-square font-size-pt4"}
							href={`https://www.instagram.com/fiduc.insta/`}
						/>
						<SocialIcon
							title={'Facebook'}
							iconClass={"fab fa-facebook-square font-size-pt4"}
							href={`https://www.facebook.com/FiducOficial/`}
						/>
					</AnimatedDivChilds>
				</div>
				<div className="hide-in-desktop pt-2 debug3 hide-in-desktop flex-grow-0 d-flex justify-content-center align-items-center">
					<AnimatedTopArrow />
				</div>
			</div>
		</Section>
	</>

}

export default FooterSection

