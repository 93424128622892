import React, {useEffect, useState} from "react"

import { AvField, AvFeedback } from "availity-reactstrap-validation"
import {getValidDomProperties} from "../Utils/DomProp";

export const validate = (value) => {
    return value.toString().trim() ? true : false
}

function FieldPassword(props) {

	const [value, setValue] = useState('')
	const [disabled, setDisabled] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setDisabled(false)
		}, 500)
	}, [])

    const handleChange = (e) => {
		const inputValue = e.target.value
		// alert(inputValue)

		//if (inputValue) alert(props.name)

		setValue(inputValue)

        if (props.onChange)
            props.onChange(props.name, inputValue, true)
    }

	useEffect(() => {
		if (props.clickIconTrigger===null)
			return

		const visible = !passwordVisible
		setPasswordVisible(visible)

		props.setFieldState(props.name, 'iconClass', visible ? "fas fa-eye" : "fas fa-eye-slash")
	}, [props.clickIconTrigger])

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)

	if (disabled)
		rest.disabled = true

    return (<div className={`
		${passwordVisible && "password-visible"}
    `}>
        <AvField
            ref={props.inputRef || undefined}
            // className={`
            // 	form-control
            // `}

            // type={passwordVisible ? "text" : 'password'}
            type={"text"}
			onCopy={(e) => e.preventDefault()}
            {...rest}

            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="off"

            onChange={handleChange}
			required={props.required}
			//validate={{val:() => value.length>0 }}
            // validate={{val:() => isValidValue(value, props) }}
            // validate={props.validation}
			value={value}
			// onDblClick={e => e.target.select()}
			// onClick={e => e.target.select()}

        /></div>
    )
}

export default FieldPassword;
