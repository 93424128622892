import React, {useEffect, useState} from "react"
import ButtonSecondary from "../../components/Common/Button/ButtonSecondary";
import AnimatedDiv from "./AnimatedDiv";
import {transitionBottomTop, transitionLeftRight, transitionPolicy} from "../../components/transitions";
import AnimatedContent from "./AnimatedContent";
import ButtonPrimary from "../../components/Common/Button/ButtonPrimary";
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import lStorage from "../../helpers/gestora/lStorage";
import {useOvermind} from "../../overmind";

const PolicyOverlay = (
	{
		children,
		className,
		onOptionsMenuClick,
		...props
	}
) => {

	const controls = useAnimation()
	const cookies = useOvermind('cookies')

	useEffect(() => {
		controls.start("visible")
		cookies.actions.getConsentStatus()
	}, [controls])

	if (cookies.state.consent?.status) {
		return null
	}

	const handleAcceptPolicy = () => {
		cookies.actions
			.setConsentStatus('accepted')
			.catch(() => alert(`Não foi possível registrar o consentimento de cookies no momento, por favor tente mais tarde`))

		controls.start("hidden")
	}

	const handleDenyPolicy = () => {
		cookies.actions
			.setConsentStatus('denied')
			.catch(() => alert(`Não foi possível registrar o consentimento de cookies no momento, por favor tente mais tarde`))

		controls.start("hidden")
	}

	return (
		<AnimatedDiv
			variants={transitionPolicy}
			controls={controls}
			className="
				d-flex debug2 section policy-container
				text-white align-items-center
				justify-content-center
			"
		>
			<div className="policy-content d-flex flex-row text-black p-3 py-2 ">
				<div className="flex-grow-1 flex-fill font-size-pt-1 pe-2">
					A <span className="font-weight-800">Fiduc</span> coleta cookies para otimizar o desempenho deste site e
					{" "}oferecer a você a melhor experiência de usuário. Ao clicar em {'"'}aceitar{'"'},
					{" "}você concorda com a nossa utilização de cookies.
					Para obter mais informações, leia a nossa{" "}
					<a
						className="font-weight-600"
						href="https://fiduc.com.br/wp-content/uploads/2022/09/fiduc-fgf-2022-08-politica-de-privacidade.pdf" target="_blank"
						rel="noreferrer"
					>
						Política de Privacidade e Utilização de Dados Pessoais.
					</a>.
				</div>
				<div className="d-flex justify-content-start flex-grow-0 debug ps-2">
					<ButtonPrimary
						className="m-2 text-nowrap"
						caption={"Aceito"}
						onClick={handleAcceptPolicy}
						// height={"100%"}
					/>
					<ButtonSecondary
						className="m-2 text-nowrap bordered"
						caption={"Não aceito"}
						onClick={handleDenyPolicy}
						// height={"100%"}
					/>
				</div>
			</div>
		</AnimatedDiv>
	)

}

export default PolicyOverlay

