import React from "react"
import Button from "./Button";

function ButtonSecondary (
	{
		caption='',
		fillWidth=false,
		className,
		bordered=false,
		height="32pt",
		width="60px",
		...props
	}
	) {

    return (
		<Button
			className={`debug3 btn f-btn f-btn-secondary  ${fillWidth && "w-100"} ${className}`}
			style={{
				height:height,
				border:bordered ? "solid 1px var(--bs-fiduc-black)" : undefined
			}}
			{...props}
		>
			{caption}
		</Button>
	)
}

export default ButtonSecondary;
