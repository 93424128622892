const Cookies = window.Fiduc?.plugins?.cookies?.instance

export const getConsentStatus = async ( {state} ) => {
	if (!Cookies)
		return Promise.reject({})

	return Cookies
		.getConsentStatus()
		.then(data => {
			state.cookies.consent = data
			console.log(data)
		})
}

export const setConsentStatus = async ( {state}, status ) => {
	if (!Cookies)
		return Promise.reject({})

	return Cookies
		.setConsentStatus(status)
		.then(data => {
			state.cookies.consent = data
			console.log(data)
		})
}


export const updateConsentDocumentPublic = async ( {state}, document ) => {
	if (!Cookies)
		return Promise.reject({})

	return Cookies
		.updateConsentDocumentPublic(document)
		.then(data => {
			console.log('updateConsentDocumentPublic', data)
			state.cookies.consent = data
		})
}

