import { useEffect, useState } from "react"

const useScrollPosition = () => {
	const [scrollPosition, setScrollPosition] = useState({
		isOnTop : true,
		y : 0,
		x : 0,
	})

	useEffect(() => {
		const updatePosition = () => {
			const x = window.pageXOffset
			const y = window.pageYOffset
			const isOnTop = !window.pageYOffset
			setScrollPosition({isOnTop, x, y})
		}
		window.addEventListener("scroll", updatePosition)
		window.addEventListener("mousewheel", updatePosition)
		updatePosition()
		return () => {
			window.removeEventListener("scroll", updatePosition)
			window.removeEventListener("mousewheel", updatePosition)
		}
	}, [])

	return scrollPosition
};

export default useScrollPosition
