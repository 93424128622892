import React, {useEffect} from "react"

const Section = ({children, id, className, ...props}) => {

	// const [animating, setAnimating] = useState(true)
	// const authInfo = AuthInfo()

	return (<>
		<div
			// id={`${id || 'no'}`}
			className={`${`page-id-${id}`} debug4 section ${className}`}
		>
			{children}
		</div>
		</>
	)

}

export default Section

