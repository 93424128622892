import React, {useState} from "react"

import { AvField, AvFeedback, InputGroupAddon  } from "availity-reactstrap-validation"
import {clearPonctuation} from "../../../../helpers/utils";
import {isValidValue} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

export const validate = (value) => {
    return value && value.toString().trim() ? true : false
}

function FieldText(props) {


	const [value, setValue] = useState('')
    const handleChange = (e) => {
		let inputValue = e.target.value

		//if (inputValue) alert(props.name)

		if (props.customFormatter) {
			inputValue = props.customFormatter(props, inputValue)
		}
		setValue(inputValue)

        if (props.onChange)
            props.onChange(props.name, inputValue, true, true)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            type={props.type || 'text'}
            {...rest}
            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="new-new-new"
            onChange={handleChange}
			value={value}
			// enabled={true}
			required={props.required}
            // validate={{val:() => isValidValue(value, props) }}
            validate={props.validation}

        />
    )
}

export default FieldText;
