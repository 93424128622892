import {setFocusOnSelectorByField} from "../../components/Common/CustomForm/Utils/DomProp";

const _ = require('lodash')

export const updateFormModel = ({state}, {formId, newValues}) => {
    const prevState = state.forms[formId].model
    state.forms[formId].model = {
        ...prevState,
        ..._.cloneDeep(newValues)
    }
    //console.log('MODEL', state.forms[formId].model)
}

export const resetForm = ({state, actions}, {formId, newValues}) => {
	//console.log('resetForm22222', newValues)
    state.forms[formId].model = newValues ? _.cloneDeep(newValues) : {}
    state.forms[formId].uploadedFiles = {}
    //actions.forms.incFormResetCount({formId})
	//console.log("resetForm", newValues)
}

export const resetTouched = ({state, actions}, {formId}) => {
	// try {
		for (const fName in state.forms[formId].fieldState) {
			state.forms[formId].fieldState[fName]['touched'] = false
		}
	// } catch (e) {
	// 	console.error(e)
	// }
}

export const getChangedFiles = ({state, actions}, {formId}) => {
	const uploadedFiles =  _.cloneDeep(state.forms[formId].uploadedFiles);

	const changedFiles = {}
	for (let d in uploadedFiles) {
		const file = uploadedFiles[d]
		if (!file || !file.changed) continue
		changedFiles[d] = file
	}
	return changedFiles
}

export const setFormModel = async ({state}, {formId, model, resetUploads=true}) => {
	// console.log("setFormModel", model)
    state.forms[formId]['model'] = model
	if (resetUploads)
		state.forms[formId]['uploadedFiles'] = {}
}

export const getModelValueByPath = ({state}, {formId, path}) => {
    //console.log('MODEL', state.forms[formId].model)
    return _.get(state.forms[formId].model, path)
}

export const submitForm = ({state}, {formId, action, method}) => {
	// if (!state.forms[formId].submitted) {
	// 	alert(document.getElementById('field-field-document').value)
	// 	// for (let error in errors) {
	// 	// 	let name = errors[error]
	// 	//
	// 	// }
	//
	// 	setTimeout(() => {
	//
	// 	}, 400)
	// 	//return
	// 	state.forms[formId].submitted = true
	// }
	const button = document.getElementById(`_submit-btn-${formId}`)
	if (button) {
		button.setAttribute('submitaction', action)
		if (method)
			button.submitmethod = method.bind(button)
		button.click()
	} else {
		console.log(`invalid form  ${formId}`)
	}
}

export const setModelValueByPath = async ({state}, {formId, path, value}) => {
	// console.log(`setModelValueByPath ${formId}.${path}`, value)
    // console.log('MODEL', state.forms[formId].model)
	//if (_.get(state.forms[formId].model, path)!=value)
    	 _.set(state.forms[formId].model, path, value)
}

export const updateModelValueValues = async ({state}, {formId, values}) => {
	for (const id in values) {
//		alert(`set ${id} value=${values[id]}`)
		_.set(state.forms[formId].model, id, values[id])
	}
}

export const setFormState =  ({state}, {formId, key, value}) => {
    state.forms[formId][key] = value
}

export const setForm =  ({state}, {formId, FormCtrl, FormRef}) => {
    state.forms[formId].Form = {formId, FormCtrl, FormRef}
    //console.log('FormCtrl', FormCtrl)
}

export const validateForm =  ({state}, {formId}) => {
    const form = state.forms[formId].FormCtrl
    const inputNames = [], inputs = form.getInputs()
    for (let inputName in inputs)
        inputNames.push(inputName)

    //console.log('inputs', inputNames)
    form.setTouched(inputNames)
}

export const setCurrentScreen = ({state}, {formId, value}) => {
    state.forms[formId]['currentScreen'] = value
}

export const setHasSubmitted = ({state}, {formId, value}) => {
    state.forms[formId]['hasSubmitted'] = value
}

export const setTotalScreens = ({state}, {formId, total}) => {
    state.forms[formId]['totalScreens'] = total
}

export const setCurrentGroup = ({state}, {formId, value}) => {
    state.forms[formId]['currentGroup'] = value
}

export const setTotalGroups = ({state}, {formId, total}) => {
    state.forms[formId]['totalGroups'] = total
}

export const setCurrentStep = ({state}, {formId, currentGroup, currentScreen}) => {
    state.forms[formId]['currentGroup'] = currentGroup
    state.forms[formId]['currentScreen'] = currentScreen
}

export const setTotalSteps = ({state}, {formId, totalScreens, totalGroups}) => {
    state.forms[formId]['totalGroups'] = totalGroups
    state.forms[formId]['totalScreens'] = totalScreens
}

export const incFormValidatedCount = async ({state}, {formId}) => {
	if (isNaN(state.forms[formId]['formValidatedCount']))
		state.forms[formId]['formValidatedCount'] = 0
    state.forms[formId]['formValidatedCount']++
}

export const incFormResetCount = async ({state}, {formId}) => {
	if (isNaN(state.forms[formId]['formResetCount']))
		state.forms[formId]['formResetCount'] = 0
    state.forms[formId]['formResetCount']++
	// alert(state.forms[formId]['formResetCount'])
}

export const setFormValidationResult = ({state}, {formId, formValidationResult}) => {
	if (state.forms[formId]['formValidationResult']===undefined)
		state.forms[formId]['formValidationResult'] = {}
    state.forms[formId]['formValidationResult'] = formValidationResult
}

export const setCustomValidationError = ({state, actions}, {formId, fieldId, fieldType, message}) => {
	const validationError = {
		field : {id : fieldId, type:fieldType},
		msg : message
	}

	const validationResult = {
		first: validationError,
		"errors": {
			[formId] : {
				[formId]: { [fieldId]: validationError}
			}
		}
	}

	actions.forms.setFormValidationResult({formId, formValidationResult:validationResult})
	setFocusOnSelectorByField(validationError.field)
}

export const setUploadedFiles = ({state}, {formId, fieldName, fileResult}) => {
	//console.log('setUploadedFiles')
    state.forms[formId]['uploadedFiles'][fieldName] = fileResult
    state.forms[formId]['model'][fieldName] = Math.random().toString()
}

export const setFieldState = ({state}, {formId, fieldName, key, value}) => {
    try {
        const fieldList = typeof fieldName !== "object"
            ? [fieldName]
            : fieldName

        for (const name of fieldList) {
            //console.log(state.forms[formId].fieldState[name])
            if (!state.forms[formId].fieldState[name])
                state.forms[formId].fieldState[name] = {}

            //const prevState = state.forms[formId].fieldState

			state.forms[formId].fieldState[name][key] = value

			// for (let name in prevState[name]) {
			// 	//state.forms[formId][name].fieldState[name] =
			// }


			/*
            state.forms[formId].fieldState = {
                ...prevState,
                ...{
                    [name] : {...prevState[name], ...{[key]:value}}
                }
            }*/
        }
    } catch(e) {
        console.log('setFieldState Error', {e, arguments})
    }
}
