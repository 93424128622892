// require('dotenv').config()
// console.log(process.env)


import React from "react"
import {environment} from "./configuration";

// import { stopReportingRuntimeErrors } from "react-error-overlay";

import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter as Router } from "react-router-dom"
// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

//import { render } from 'react-dom'
import { createOvermind } from 'overmind'
import { config } from './overmind/index'
import { Provider as OvermindProvider } from 'overmind-react'

const overmind = createOvermind(config, { devtools: false })

require("./helpers/polyfill/customEvent")
//const overmind = createOvermind(config)

const app = (
  <OvermindProvider value={overmind}>
	  <Router>
          <App />
	  </Router>
  </OvermindProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.register()
