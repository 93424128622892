import React from 'react'

export default function SectionFooterText() {
  return (
    <>
      <span className="font-weight-800 font-family-title">FIDUC.</span>
      <span className="font-weight-500 font-family-title">
        <br />Investimentos e
        <br />proximidade.
      </span>
    </>
  )
}