import React, {useEffect, useMemo} from "react"

//import LogoDucDark from "../../assets/images/logos/logo-duc-dark.svg"
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import { useInView } from "react-intersection-observer";

const AnimatedDivChilds = (
	{
		children,
		staggerChildren = 0.025,
		className,
		runOnce=false,
		controls = useAnimation(),
		delayChildren,
		threshold = .25,
		...props
	}
) => {

	const [ref, inView] = useInView({threshold})

	useEffect(() => {
		if (inView) {
			controls.start("visible")
		} else {
			if (!runOnce)
				controls.start("hidden")
		}
	}, [controls, inView])

	const container = useMemo(() => {
		return {
			visible: {
				transition: {
					staggerChildren,
					delayChildren,
				}
			}
		}
	}, [])

	return (
			<motion.div
				ref={ref}
				className={className}
				style={{display:'inline-block'}}
				initial={"hidden"}
				animate={controls}
				variants={container}
			>
				{children}
			</motion.div>
	)

}

export default AnimatedDivChilds

