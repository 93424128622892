
const _ = require('lodash')

//import states directly
import {defaultForms} from "../../overmind/forms/state"

export const defaultApp = {
	remoteListsLoaded : false,
	remoteListsLoading : false,
	introRegisterButtonVisible : false,
}

export const app = _.cloneDeep(defaultApp)

export const logoutResetStates = {
	app : defaultApp,
	forms : defaultForms,
}

