import React, { useEffect, useState } from "react"

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";

import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import {
	transitionAmplify,
	transitionOpacity,
	transitionOpacitySlowStartFast,
	transitionRightLeft
} from "../../../components/transitions";
import AnimatedContent from "../../components/AnimatedContent";

import BradescoImage from "../../../assets/images/partners/bradesco.png"
import FiducRioBravoImage from "../../../assets/images/partners/fiducriobravo.png"
import IcatuImage from "../../../assets/images/partners/icatu.png"
import BlackJaguarImage from "../../../assets/images/partners/black-jaguar-fundation.png"
import PWCImage from "../../../assets/images/partners/pwc.png"
import SectionFooterText from "pages/components/SectionFooterText";

const PartnerBlock = (
	{
		image,
		title,
		className,
		style,
	}
) => {
	return <>
		<AnimatedContent
			variants={transitionOpacity}
			className={`font-size-rem-1 partner-block debug3 d-flex flex-column justify-content-center align-items-center `}

		>
			<div className={`d-flex flex-column justify-content-center align-items-center ${className}`} style={style}>
				{!!title ? (
					<>
						<div className="debug6 partner-logo"><img src={image} height={48} /></div>
						<div className="debug6 pt-2 ">{title}</div>
					</>
				) : (
					<div className="debug6 partner-logo"><img src={image} height={70} /></div>
				)}
			</div>
		</AnimatedContent>
	</>
}

const ImageBlock = (
	{
		title,
		imageClass,
		description,
	}
) => {

	return <>
		<AnimatedContent threshold={.5} variants={transitionAmplify} className="debug5 image-item ">
			<div className="image-block d-flex font-size-pt0  ">
				<div className={`image image-wide ${imageClass} flex-grow-0`}>

				</div>
				<div className="text d-flex flex-column flex-grow-1 flex-fill">
					<div className="font-weight-600">{title}</div>
					<div className=" ">{description}</div>
				</div>
			</div>
		</AnimatedContent>
	</>
}

const BePlannerSection = ({ history, props }) => {

	return <>
		<Section className="section-be-planner" id="desenvolvaseunegocio">

			<div className="content-left d-flex flex-column">
				<div className="debug2 flex-grow-1 flex-fill d-flex align-items-end justify-content-end text-align-right">

				</div>
				<div className="debug2 flex-grow-0">
					<div className="fiduc-slogan-bottom ">
						<SectionFooterText />
					</div>
				</div>
			</div>
			<div className="content-right pt-5 d-flex flex-grow-1 flex-fill d-flex flex-column  ">
				<div className="debug3 ">
					<AnimatedDivChilds staggerChildren={.15} className="d-block debug4 text-align-left text-info-container">
						<AnimatedCharacters
							text={'Desenvolva seu negócio'}
							splitChars={'none'}
							fontClassName="font-family-title font-weight-700 font-size-pt14 text-uppercase"
							variants={transitionRightLeft}
						/>
					</AnimatedDivChilds>
				</div>
				<AnimatedDivChilds staggerChildren={.15} className="debug3 no-select pt-5 pb-4 content">
					<div className="debug5 image-item text-center">
						<ImageBlock
							title={"Capacitação"}
							description={<>
								A Academia Fiduc é o centro da
								<br />rede de Planejadores Fiduciários
								<br />com troca de conhecimento,
								<br />orientações e experiências.
							</>}
							imageClass={"image-aplicacao-2"}
						/>
					</div>
					<div className="debug5 image-item text-center">
						<ImageBlock
							title={"Comunidade"}
							description={<>
								Aqui você participa de uma
								<br />comunidade de empreendedores,
								<br />criando sua própria rede de
								<br />relacionamento e troca
								<br />de experiências.
							</>}
							imageClass={"image-comunidade-2"}
						/>
					</div>
					<div className="debug5 image-item text-center">
						<ImageBlock
							title={"Sustentabilidade"}
							description={<>
								Na Fiduc você passa a
								<br />praticar o ASG* no seu
								<br />dia-a-dia.
								<div style={{ fontSize: '80%', marginTop: 10 }}>* Ambiental, Social e Governança</div>
							</>}
							imageClass={"image-sustentabilidade-2"}
						/>
					</div>
				</AnimatedDivChilds>
				<div className="partners debug3 d-flex flex-wrap no-select py-4 justify-content-center align-items-center flex-column">
					<div className="font-weight-600 font-size-pt1">Parceiros</div>
					<AnimatedDivChilds staggerChildren={.15} className="debug5 image-item d-flex flex-row partner-container ">
						<PartnerBlock
							title="Administração e Custódia"
							image={BradescoImage}
						/>
						<PartnerBlock
							title="Comitê de Investimentos"
							image={FiducRioBravoImage}
						/>
						<PartnerBlock
							title="Seguradora"
							image={IcatuImage}
						/>
						<PartnerBlock
							title=""
							image={BlackJaguarImage}
						/>
						<PartnerBlock
							title="Auditoria"
							image={PWCImage}
						/>
					</AnimatedDivChilds>
				</div>

			</div>
		</Section>
	</>

}

export default BePlannerSection

