import React, {useEffect} from "react"
import {useOvermind} from "../../overmind";
import { useHistory, useLocation, matchPath } from "react-router-dom"

const PageHref = (
	{
		Tag='a',
		children,
		id,
		onClick,
		setHistory=true,
		...props
	}
	) => {
	const app = useOvermind('app')
	const history = useHistory('app')
	const location = useLocation()

	const handleLink = (e, id) => {
		onClick && onClick(e, id)
		const pageId = location.pathname.slice(1)
		if (pageId!==id) {
			history.push(`/${id}`)
		} else {
			app.actions.moveToPage(id)
		}
		e.preventDefault()
	}

	return <Tag
		{...props}
		onClick={e => handleLink(e, id)}
		href="#"
	>
		{children}
	</Tag>

}

export default  PageHref
