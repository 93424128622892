import React, {useEffect, useState} from "react"

import Section from "../../components/Section";

import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import {
	transitionAmplify,
	transitionBottomTop, transitionLeftRight, transitionOpacity,
	transitionOpacitySlowStartFast,
	transitionRightLeft, transitionRightLeftSmall
} from "../../../components/transitions";
import AnimatedContent from "../../components/AnimatedContent";
import AnimatedLogoCircle from "../../components/AnimatedLogoCircle";
import {ReactComponent as CloseX} from "../../../assets/images/icons/close-x.svg"
import {motion, useAnimation} from "framer-motion/dist/framer-motion";
import PageHref from "../../components/PageHref";


const MenuItem = (
	{
		title,
		id,
		className='link-green',
		onClick,
	}
) => {
	return <>
		<PageHref
			className={`debug4 ${className} py-1`}
			id={id}
			onClick={onClick}
		>
			<AnimatedCharacters
				text={title}
				splitChars={'none'}
				fontClassName="debug5 font-weight-500 font-size-pt4 text-uppercase font-weight-500 "
				variants={transitionRightLeft}
			/>
		</PageHref>
	</>
}

const SocialIcon = (
	{
		title,
		href='#',
		className='link-dark',
		iconClass='fab fa-instagram',
	}
) => {
	return <>
		<a
			href={href}
			className={`debug4 ${className} `}
			title={title}
			target={"_blank"}
			rel="noreferrer"
		>
			<AnimatedContent
				className="debug5 font-weight-500 text-uppercase text-align-center "
				variants={transitionBottomTop}
			>
				<span className={`${iconClass} `} style={{minWidth:'24pt'}}/>
			</AnimatedContent>
		</a>
	</>
}

const MenuOptions = (
	{
		isOpen,
		onCloseMenuClick,
		...props
	}
) => {
	const controls = useAnimation()

	useEffect(() => {
		if (isOpen) {
			window.scrollTo(0, 0);
			document.body.classList.add('overflow-hidden')
			controls.start("visible")
		} else  {
			controls.start("hidden")
			document.body.classList.remove('overflow-hidden')
		}
	}, [isOpen, controls])

	return <>
		<Section className={`menu-options ${isOpen && "appear"} d-flex justify-content-center align-items-center debug4`}>
			<div className="top-menu-options position-absolute debug3 d-flex flex-column pb-4 pt-3 pe-4">
				<a href={'#'} onClick={onCloseMenuClick} className="link-green" title="Fechar menu">
					<CloseX />
				</a>
			</div>
			<div className="debug2 d-flex flex-grow-1 flex-fill d-flex flex-column  ">
				<div className="debug3 flex-grow-0 d-flex justify-content-center align-items-center ">
					{isOpen && <AnimatedLogoCircle className="logo-menu-options text-white" />}
				</div>
				<div className="debug1 d-flex flex-fill flex-row flex-grow-1 justify-content-center">
					<AnimatedDivChilds
						controls={controls}
						staggerChildren={.15}
						delayChildren={.5}
						className="d-flex flex-column  text-align-center text-info-container text-white "
					>
						<MenuItem
							title={'Seja Indicador'}
							id={'seja-indicador'}
							onClick={onCloseMenuClick}
						/>
						<MenuItem
							title={'Ganhos'}
							id={'simular-seus-ganhos'}
							onClick={onCloseMenuClick}
						/>
						<MenuItem
							title={'Depoimentos'}
							id={'depoimentos'}
							onClick={onCloseMenuClick}
						/>
						{/*<MenuItem*/}
						{/*	title={'`Quem somos?`'}*/}
						{/*	href={'https://fiduc.com.br/sobre-investimentos-tripla-filtragem-alinhamento-de-interesses-comite-de-investimentos/'}*/}
						{/*/>*/}
						{/*<MenuItem*/}
						{/*	title={'Investimentos'}*/}
						{/*	href={'https://fiduc.com.br/investimentos-fiduc-jeito-fiduc-de-investir/'}*/}
						{/*/>*/}
						{/*<MenuItem*/}
						{/*	title={'FI Mídia'}*/}
						{/*	href={'https://fiduc.com.br/fi-midia-fiduc-investimentos-e-proximidade/'}*/}
						{/*/>*/}
						{/*<MenuItem*/}
						{/*	title={'Contato'}*/}
						{/*	href={'https://fiduc.com.br/quem-somos-fiduc-investimentos-e-proximidade/'}*/}
						{/*/>*/}
					</AnimatedDivChilds>
				</div>
			</div>
			<div className="social-menu-options position-absolute debug3 d-flex flex-column justify-content-center align-items-center no-select pb-4 pt-3">
				<AnimatedDivChilds
					controls={controls}
					staggerChildren={.15}
					className="d-flex flex-row "
				>
					<SocialIcon
						title={'YouTube'}
						iconClass={"fab fa-youtube text-white font-size-pt1"}
						href={`https://www.youtube.com/c/Fiducoficial`}
					/>
					<SocialIcon
						title={'Instagram'}
						iconClass={"fab fa-instagram-square text-white font-size-pt1"}
						href={`https://www.instagram.com/fiduc.insta/`}
					/>
					<SocialIcon
						title={'Facebook'}
						iconClass={"fab fa-facebook-square text-white font-size-pt1"}
						href={`https://www.facebook.com/FiducOficial/`}
					/>
					<SocialIcon
						title={'Linkedin'}
						iconClass={"fab fa-linkedin-in text-white font-size-pt1"}
						href={`https://www.linkedin.com/company/fiduc-brasil/`}
					/>
				</AnimatedDivChilds>
			</div>
		</Section>
	</>

}

export default MenuOptions

