import React, { useEffect, useState } from "react"

//import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Section from "../../components/Section";
import AnimatedDiv from "../../components/AnimatedDiv";
import {
	transitionBottomTop,
	transitionLeftRight,
	transitionOpacity, transitionOpacitySlow,
	transitionRightLeft, transitionRightLeft20,
	transitionRightLeftSmall

} from "../../../components/transitions";

import AnimatedText from "../../components/AnimatedText";
import AnimatedDivChilds from "../../components/AnimatedDivChilds";
import AnimatedCharacters from "../../components/AnimatedCharacters";
import AnimatedContent from "../../components/AnimatedContent";
import ButtonSecondary from "../../../components/Common/Button/ButtonSecondary";
import { useOvermind } from "../../../overmind";
import { useInView } from "react-intersection-observer";
import PageHref from "../../components/PageHref";
import SectionFooterText from "pages/components/SectionFooterText";

const SloganDescription = ({ className, style }) => {
	return (
		<div className={className} style={style}>
			A melhor oportunidade
			<br />para você ter seu próprio negócio
			<br />é se juntar à <span className="font-weight-800 font-family-title">maior Comunidade</span>
			<br />de <span className="font-weight-800 font-family-title">Planejadores Fiduciários</span> do Brasil.
		</div>
	)
}

const SloganBottom = ({ className }) => {
	return (
		<div className={className}>
			<div className="fiduc-slogan-bottom ">
				<SectionFooterText />
			</div>
		</div>
	)
}

const IntroSection = (
	{
		...props
	}
) => {
	const app = useOvermind('app')

	const [registerButtonRef, registerButtonInView] = useInView({
		threshold: .1,
		rootMargin: '-130px 0px 0px 0px',
	})

	useEffect(() => {
		console.log('registerButtonInView', registerButtonInView)
		app.actions.setIntroRegisterButtonVisible(registerButtonInView)
	}, [registerButtonInView])

	return <>
		<Section className="section-intro overflow-hidden " id={"intro"}>
			<div className="content-left bg-fiduc-blue d-flex flex-column">
				<div 
					className="debug2 flex-grow-1 flex-fill d-flex flex-column justify-content-center text-align-right content-left-text" 
					style={{ background: '#fff1' }}
				>
					<AnimatedDiv variants={transitionOpacitySlow}>
						<SloganDescription className="font-family-title text-align-right " />
					</AnimatedDiv>
				</div>
				<SloganBottom className="debug2 flex-grow-0" />
			</div>
			<div className="content-right ">
				<div className="debug background-intro position-relative" />
				<div className="intro-message position-absolute text-white " >
					<AnimatedDivChilds staggerChildren={.15} className="debug2 w-100 d-flex flex-column  text-uppercase">
						<div className="flex-grow-0 " >
							<div className="d-flex flex-row">
								<div className="flex-grow-0">
									<AnimatedCharacters
										text={"Só a Fiduc..."}
										fontClassName="font-family-title font-size-view2 line-height-13 mb-4"
										variants={transitionLeftRight}
									/>
									<AnimatedCharacters
										text={"Paga Bônus!"}
										fontClassName="font-weight-700 font-family-title font-size-view5 line-height-13"
									/>
									<AnimatedCharacters
										text={"Garante que o Cliente é seu!"}
										fontClassName="font-weight-700 font-family-title font-size-view5 line-height-13"
									/>
								</div>
								<div className="flex-grow-1 flex-fill">

								</div>
							</div>
						</div>
						<div className="d-flex flex-column-reverse flex-grow-1 flex-fill ">
							<AnimatedContent variants={transitionBottomTop}>
								<SloganDescription
									className="debug5 hide-in-desktop slogan-description font-family-title font-size-view0 "
								/>
							</AnimatedContent>
						</div>
						<div
							ref={registerButtonRef}
							className="debug flex-grow-0 container-be-investor"
						>
							<AnimatedContent
								splitChars="none"
								className="debug4  "
								variants={transitionBottomTop}
							>
								<PageHref Tag={ButtonSecondary}
									caption="Seja Fiduc"
									fillWidth={true}
									id={"seja-um-planejador"}
								/>
							</AnimatedContent>
						</div>
						<div className="flex-grow-0 hide-in-desktop slogan-desktop" >
							<SloganBottom className=" flex-grow-0 " />
						</div>
					</AnimatedDivChilds>
				</div>
				{/*<div className="debug4 intro-message h-100 position-absolute text-white " style={{top:'140pt'}}>*/}
				{/*	<AnimatedDivChilds className="debug2 d-flex flex-column  ">*/}
				{/*	</AnimatedDivChilds>*/}
				{/*</div>*/}


				{/*<div className="content-right no-pointer-events position-absolute d-flex align-items-end text-white" style={{top:0}}>*/}

				{/*</div>*/}
			</div>
		</Section>
	</>

}

export default IntroSection

